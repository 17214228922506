<template>
  <div class="merchant-box">
    <h2 class="title">
      <span>Find the Best Takeaways & Restaurants Near You</span>
      <button class="btn" @click="goBrowse('')">See All</button>
    </h2>
    <div class="item-box">
      <swiper class="my-swiper" :options="swiperOption">
        <swiper-slide class="swiper-slide" v-for="item in merchantList" :key="item.merchantId">
          <MerchantItem :item="item"></MerchantItem>
        </swiper-slide>
      </swiper>
      <!--前进后退按钮-->
      <div class="swiper-button-prev1" slot="button-prev">
        <i class="el-icon-arrow-left" ></i>
      </div>
      <div class="swiper-button-next1" slot="button-next">
        <i class="el-icon-arrow-right" ></i>
      </div>
    </div>
    <h2 class="title">
      <span>Top picks with free delivery</span>
      <button class="btn" @click="goBrowse('noDeliveryFee')">See All</button>
    </h2>
    <div class="item-box">
      <swiper class="my-swiper" :options="swiperOption2">
        <swiper-slide class="swiper-slide" v-for="item in merchantListNoDe" :key="item.merchantId">
          <MerchantItem :item="item"></MerchantItem>
        </swiper-slide>
      </swiper>
      <!--前进后退按钮-->
      <div class="swiper-button-prev2" slot="button-prev">
        <i class="el-icon-arrow-left" ></i>
      </div>
      <div class="swiper-button-next2" slot="button-next">
        <i class="el-icon-arrow-right" ></i>
      </div>
    </div>
  </div>
</template>

<script>
import MerchantItem from '../MerchantItem.vue'
  export default {
    components: {
      MerchantItem
    },
    props: {
      merchantList: Array,
      merchantListNoDe: Array  // 没有运费
    },
    data() {
      return {
        swiperOption: {
          //幻灯片放映方向
          direction: "horizontal", //通常不与左右按钮一同使用
          slidesPerView: 3,
          spaceBetween: 23,
          //分页器配置项
          pagination: {
              el: ".swiper-pagination", //分页器的类名
              clickable: true, // 点击分页器跳切换到相应的幻灯片
              bottom: 0,
          },
          //前进后退按钮
          navigation: {
              nextEl: ".swiper-button-next1",
              prevEl: ".swiper-button-prev1",
          },
          //幻灯片播放配置项
          loop: false, //是否循环播放
          speed: 1000, // 发生页面切换动画时，动画的切换速度
          autoPlay: false,
          breakpoints: {
              1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
              },
              768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
              },
              640: {
                  slidesPerView: 4,
                  spaceBetween: 10,
              },
              320: {
                  slidesPerView: 4,
                  spaceBetween: 10,
              },
          },
          on: {
              slideChangeTransitionEnd: function () {
                  // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
              },
          },
        },
        swiperOption2: {
          //幻灯片放映方向
          direction: "horizontal", //通常不与左右按钮一同使用
          slidesPerView: 3,
          spaceBetween: 23,
          //分页器配置项
          pagination: {
            el: ".swiper-pagination", //分页器的类名
            clickable: true, // 点击分页器跳切换到相应的幻灯片
            bottom: 0,
          },
          //前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next2",
            prevEl: ".swiper-button-prev2",
          },
          //幻灯片播放配置项
          loop: false, //是否循环播放
          speed: 1000, // 发生页面切换动画时，动画的切换速度
          autoPlay: false,
          breakpoints: {
            1024: {
                slidesPerView: 4,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            320: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
          },
          on: {
            slideChangeTransitionEnd: function () {
                // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
            },
          },
        },
      }
    },
    mounted() {

    },
    methods: {
      goBrowse() {
        this.$router.push({
          path: "/browse",
          query: {
            searchName: "",
            lng: '',
            lat: '',
            searchLoc: '',
            transType: '',
          },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .merchant-box {
    margin: 200px auto 0;
    width: 1400px;
    height: 1050px;
    .title {
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      word-spacing: 5px;
      span {
        font-size: 36px;
        margin-right: 25px;
        color: #333333;
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        font-family: 'Source Sans Pro', sans-serif;
      }
      .btn {
        height: 54px;
        width: 140px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #09CA6A;
        border-radius: 37px;
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        cursor: pointer;
        transition: all .2s ease-in-out;
        
        &:hover {
          background: #09b35e;
        }
      }
    }

    .item-box {
      position: relative;
      width: 1300px;
      margin: 30px auto 0;
      padding-bottom: 20px;
      zoom: 1.05;
    }
    .my-swiper {
      width: 100%;
      .swiper-slide {
        margin: 10px 25px 10px 0px;
      }
    }
    .swiper-pagination {
      margin-top: 45px;
      left: 44%;
      transform: translateY(-50%);
      :deep(.swiper-pagination-bullet) {
        margin-right: 8px!important;
      }
    }
    .swiper-button-prev1, .swiper-button-next1, .swiper-button-prev2, .swiper-button-next2 {
      cursor: pointer;
      position: absolute;
      z-index: 20;
      background: #fff;
      width: 76px;
      height: 76px;
      top: 160px;
      border-radius: 38px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      &::after {
        font-size: 26px;
        color: #000;
      }
      i {
        font-size: 30px;
      }
    }
    .swiper-button-prev1, .swiper-button-prev2 {
      left: -60px;
    }
    .swiper-button-next1, .swiper-button-next2 {
      right: -50px;
    }
  }

@media screen and (max-width: 950px) {
  .merchant-box {
    margin: 158px auto 0;
    display: none;
  }
}
</style>