<template>
    <div class="my-order">
        <div class="order-box">
            <!-- <h2 class="title">My Orders</h2> -->
            <div style="height: 200px;" v-if="loading && !orderList.length" ></div>
            <ul v-if="!loading || orderList.length">
                <li v-for="(item) in orderList" :key="item.orderId" >
                    <div :class="['order-card',{ 'card-color': false }]">
                        <div class="card-left flex-col-center ">
                            <img :src="item.photo" alt="">
                            <div :class="['status-tag', ('status-'+item.status)]" >{{ item.status }}</div>
                            <div v-if="isMobile" class="order-number"># {{ item.orderId }}</div>
                        </div>
                        <div class="card-center">
                            <div class="name-box">
                                <div class="name">{{ item.merchantName }}</div>
                                <div class="rate-box" @click="Comment(item)" v-if="(!item.isReview && item.status=='accepted')&&!isMobile">
                                    <el-rate v-model="nowRate" :disabled="true"></el-rate>
                                </div>
                                <span style="padding-left: 15px;" @click="getDetails(item)" class="receipt-content" v-if="isMobile">View Receipt</span>
                            </div>
                            <div class="desc" >
                                <span>{{ item.items.split(',').length }} items for {{item.currencySign}}{{ item.price }}</span> 
                                <span class="point"></span>
                                <span style="margin-left:5px;">{{ item.orderTime }}</span>
                                <span style="margin-left: 15px;" @click="getDetails(item)" class="under-line">View Receipt</span>
                            </div>
                            
                            <div class="desc" style="font-weight: bold;color:#000;" v-if="item.merchantDeliveryEstimation">ETA: {{item.merchantDeliveryEstimation}}</div>
                            <div class="pending-content" v-if="!isMobile && item.status=='unpaid'">your order is unpaid please pay again</div>
                            <div class="under-line" v-if="!isMobile && (item.status=='pending' || item.status == 'compeleted')" @click="dialogVisible = true" style="text-decoration: underline;">why my order is showing as pending?</div>
                            <div class="cancel-content" v-if="!isMobile && item.status=='cancelled'">your order was cancelled due to weather conditions</div>
                            <div 
                            class="under-line" 
                            v-if="!isMobile&&item.status=='cancel'" 
                            style="text-align: center; text-decoration: underline; font-size:17px; color:#8F9698;"
                            @click="refundPolicyVisible = true">
                                View refund policy
                            </div>
                            <div class="rate-box" @click="Comment(item)" v-if="(!item.isReview && item.status=='accepted')&&isMobile">
                                <!-- <el-rate v-model="nowRate" :disabled="true" :colors="['#aa0bc0','#aa0bc0','#aa0bc0']"></el-rate> -->
                                <el-rate v-model="nowRate" :disabled="true"></el-rate>
                                <span style="color:#8F9698">({{ nowRate }})</span>
                            </div>
                            <!-- 移动端展示 -->
                            <div class="desc-mobile" >
                                <div class="line" >
                                    <span>Order Time:</span>
                                    <span style="margin-left:5px;">{{ item.orderTime }}</span>
                                </div>
                                <div class="line">
                                    <span>Items:</span>
                                    <span style="margin-left:5px;">{{ item.items.split(',').length }} </span> 
                                </div>
                                <!-- <div class="line" >
                                    <span>Order Number:</span>
                                    <span style="margin-left:5px;">{{ item.orderId }}</span>
                                </div> -->
                                <!-- <div class="line" style="display: -webkit-box;" >
                                    <span>Items:</span>
                                    <span style="margin-left:5px;">{{ item.items }}</span>
                                </div> -->
                                <div class="line">
                                    <span>Total:</span>
                                    <span style="margin-left:5px;">{{item.currencySign}}{{ item.price }}</span>
                                </div>
                                <div class="line" style="font-weight: bold;" v-if="item.merchantDeliveryEstimation">
                                    <span>ETA: </span>
                                    <span style="margin-left:5px;">{{item.merchantDeliveryEstimation}}</span>
                                </div>
                                <div class="line pending-content"  v-if="item.status=='unpaid'">
                                    <span >your order is unpaid please pay again</span>
                                </div>
                                <div class="line under-line" @click="dialogVisible = true" v-if="item.status=='pending' || item.status == 'compeleted'">
                                    <span >why my order is showing as pending?</span>
                                </div>
                                <div class="line cancel-content" v-if="item.status=='cancelled'">
                                    <span >your order was cancelled due to weather conditions</span>
                                </div>

                                <!-- <div class="line price" >
                                    <span style="flex: 1;" ></span>
                                    <span>Total:</span>
                                    <span style="margin-left:5px;">{{item.currencySign}}{{ item.price }}</span>
                                </div> -->
                            </div>
                            <!-- <div class="rate-box" @click="Comment(item)" v-if="(!item.isReview && item.status=='accepted')">
                                <el-rate v-model="nowRate" :disabled="true" :colors="['#aa0bc0','#aa0bc0','#aa0bc0']"></el-rate>
                                <el-rate v-model="nowRate" :disabled="true"></el-rate>
                                <span class="review-btn" >
                                    Review
                                </span>
                                <img class="sumb-up img-btn" v-if="!item.isReview" src="@/assets/icon/sumb_up.png" alt="">
                                <img class="sumb-down img-btn" v-if="!item.isReview" src="@/assets/icon/sumb_down.png" alt="">
                            </div> -->
                        </div>
                        <div class="card-right flex-col-center " >
                            <!-- <div class="btn btn-view" @click="getDetails(item)">View Receipt</div> -->
                            <div class="btn btn-visit " @click="goMerchant(item)">Visit Store</div>
                            <div class="btn" @click="goMerchant(item)">Re-order</div>
                            <!-- <div class="btn btn-again" @click="goMerchant(item)">Order Again</div> -->
                            <div class="btn btn-pay" @click="paynow(item)" v-if="item.status=='unpaid'">Pay</div>
                        </div>
                    </div>
                    <div class="card-bottom" >
                        <!-- <div class="btn btn-view" @click="getDetails(item)">Receipt</div> -->
                        <div class="btn btn-visit " @click="goMerchant(item)">Visit Store</div>
                        <div class="btn" @click="goMerchant(item)">Re-order</div>
                        <!-- <div class="btn btn-again" @click="Comment(item)" v-if="!item.isReview && item.status=='accepted'">
                            Review
                        </div> -->
                        <div class="btn btn-again" @click="Comment(item)" v-if="item.isReview">
                            View Comment
                        </div>
                        <div class="btn btn-pay" @click="paynow(item)" v-if="item.status=='unpaid'">Pay</div>
                    </div>
                    <div style="display: flex; flex-direction: center; width: 100%;" >
                        <div class="review-box" @click="Comment(item)" v-if="!item.isReview && item.status=='accepted'">
                            <span class="review-btn" >Review</span>
                            <img class="sumb-up img-btn" v-if="!item.isReview" src="@/assets/icon/sumb_up.png" alt="">
                            <img class="sumb-down img-btn" v-if="!item.isReview" src="@/assets/icon/sumb_down.png" alt="">
                        </div>
                    </div>
                </li>
            </ul>
            <div class="bottom-box" v-if="!loading && orderList.length">
                <a href="/faqs" class="help-btn">Need Help?</a>
                <div class="more-btn" v-if="!loading && total>this.pages.pageSize*this.pages.pageNum" @click="getMoreOrder">Show More</div>
            </div>
            <!-- <div class="more-btn" v-if="!loading && total>this.pages.pageSize*this.pages.pageNum" @click="getMoreOrder">Show More</div> -->
            <div class="loading-box" v-if="loading">
                <i class="el-icon-loading"></i>
            </div>
            <div class="empty-block" v-if="!loading && !orderList.length">
                <img src="@/assets/menu/empty_cart3.png" alt="empty">
            </div>
        </div>

        <!-- 订单详情弹窗 -->
        <el-dialog
            :visible.sync="receptModal"
            :append-to-body="true"
            :close-on-click-modal="false"
            :width="isMobile?'96%':'450px'"
            center
            top="50px">
            <div class="form-title" slot="title">
                <h2>Order Receipt</h2>
            </div>
            <div class="order-form">
                <div class="form-line">
                    <span class="label" >Name</span>
                    <span class="value" >{{ viewForm.name.trim()?viewForm.name:'Alleat User' }}</span>
                </div>
                <div class="form-line">
                    <span class="label" >Merchant Name</span>
                    <span class="value" >{{ viewForm.merchantName }}</span>
                </div>

                <div class="form-line">
					<div class="label">{{$t('merchant.Telephone')}}</div>
					<div class="value">{{viewForm.tele}}</div>
				</div>
			
				<div class="item-empty"></div>
			
				<div class="form-line">
					<div class="label">{{$t('merchant.TransType')}}</div>
					<div class="value">{{viewForm.transType}}</div>
				</div>
				<div class="form-line">
					<div class="label">{{$t('merchant.PaymentType')}}</div>
					<div class="value">{{payMap[viewForm.patmentType]}}</div>
				</div>
				<div class="form-line">
					<div class="label">{{$t('merchant.ref')}}</div>
					<div class="value">{{viewForm.orderNo}}</div>
				</div>
				<div class="form-line">
					<div class="label" style="width: 80px;">{{$t('merchant.TRNDate')}}</div>
					<div class="value">{{viewForm.orderTime}}</div>
				</div>
				<div class="form-line">
					<div class="label" style="width: 120px;">{{ viewForm.transType == 'delivery'?'DeliveryDate':'AppointmentTime' }}</div>
					<div class="value">{{viewForm.deliveryDateStr?viewForm.deliveryDateStr:(viewForm.deliveryDate+' ' +viewForm.deliveryTime)}}</div>
				</div>
				<div class="form-line">
					<div class="label" style="width: 120px;">{{'deliveryASAP'}}</div>
					<div class="value">{{viewForm.deliveryAsap == '1'?'Yes':'No'}}</div>
				</div>
				<div class="form-line" v-if="viewForm.transType == 'delivery'">
					<div class="label">{{$t('merchant.DeliveryTo')}}</div>
					<div class="value">{{viewForm.address}}</div>
				</div>
				<div class="item-empty"></div>
			
				<div class="item-empty"></div>
				<!-- 下单商品 -->
				<div class="food-box">
					<div v-for="(item,index) in viewForm.items" :key="index">
						<div style="font-weight: 600;font-size: 14px;margin: 10px 0 10px;">
							{{ item.catName }}
						</div>
						<div class="form-line" :key="item.itemName">
							<div class="label"  style="display: flex;">
								<span style="padding-left: 10px;padding-right: 10px;">{{item.count}}×</span>
								<span >{{item.itemName.replace(/amp;/g,'')}}</span>
								<br />
							</div>
							<div class="value">{{viewForm.currencySign}}{{ (item.price*item.count).toFixed(2) }}</div>
						</div>
						<div style="padding-left: 35px;margin-top: 5px;">{{viewForm.currencySign}}{{ item.price.toFixed(2) }}</div>
						<div v-for="(subItem,ind) in item.itemAddons" :key="ind">
							<div class="meat-options">
								{{ subItem.addonCategory }}
							</div>
							
							<div class="form-line" :style="'padding:0 0 10px 10px;'+(ind===item.itemAddons.length-1?'border-bottom: 1px solid gray;':'')" :key="subItem.id">
								<div class="label" style="display: flex;">
									<span style="padding-right: 10px;">{{subItem.addonQty}}×</span>
									<span style="padding-right: 10px;">{{subItem.addonPrice.toFixed(2)}}</span>
									<span style="color: #b5b5b5;" >{{subItem.addonName.replace(/amp;/g,'')}}</span>
									<br />
								</div>
								<div class="value">{{ (subItem.addonPrice*subItem.addonQty).toFixed(2)*1?viewForm.currencySign+(subItem.addonPrice*subItem.addonQty).toFixed(2):'-' }}</div>
							</div>
						</div>
					</div>
				</div>
			
				<div class="item-empty"></div>
				<div class="form-line">
					<div class="center-tips">{{$t('merchant.Subtotal')}}</div>
					<div class="value">{{viewForm.currencySign}}{{viewForm.subTotal?viewForm.subTotal.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line" v-if="['delivery','pickup'].includes(viewForm.transType) && viewForm.packagingFee">
					<div class="center-tips">{{$t('merchant.Packingfee')}}</div>
					<div class="value">{{viewForm.currencySign}}{{viewForm.packagingFee?viewForm.packagingFee.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line" v-if="['delivery'].includes(viewForm.transType) && viewForm.deliveryFee">
					<div class="center-tips">{{$t('merchant.Deliveryfee')}}</div>
					<div class="value">{{viewForm.currencySign}}{{viewForm.deliveryFee?viewForm.deliveryFee.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line" v-if="viewForm.serviceFee">
					<div class="center-tips">{{$t('merchant.Servicefee')}}</div>
					<div class="value">{{viewForm.currencySign}}{{viewForm.serviceFee?viewForm.serviceFee.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line" v-if="viewForm.spendPoint">
					<div class="center-tips">{{$t('merchant.Points')}}</div>
					<div class="value">- {{viewForm.spendPoint?viewForm.spendPoint:'0'}}</div>
				</div>
                <div class="form-line"  v-if="viewForm.offType == 'discount'">
					<div class="center-tips">{{$t('merchant.Discount')}}</div>
					<div class="value">{{viewForm.offAmount?'- '+viewForm.currencySign+viewForm.offAmount.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line"  v-else-if="viewForm.offType == 'freeItem'">
					<div class="center-tips">{{$t('merchant.Discount')}}</div>
					<div class="value">{{viewForm.offItem}}</div>
				</div>
                <div class="form-line" v-if="viewForm.couponDiscount">
					<div class="center-tips">{{$t('merchant.CouponDiscount')}}</div>
					<div class="value">{{viewForm.couponDiscount?'- '+viewForm.currencySign+viewForm.couponDiscount.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line">
					<div class="center-tips">{{$t('merchant.Tip')}}</div>
					<div class="value">{{viewForm.currencySign}}{{viewForm.tip?viewForm.tip.toFixed(2):'0.00'}}</div>
				</div>
				<div class="form-line"  v-if="viewForm.point">
					<div class="center-tips">{{$t('merchant.Points')}}</div>
					<div class="value">- {{viewForm.point}}</div>
				</div>
				<!-- 无折扣 -->
				<!-- <div class="form-line" v-else >
					<div class="center-tips">{{$t('merchant.Discount')}}</div>
					<div class="value">￡0.00</div>
				</div> -->
				<div class="form-line">
					<div class="center-tips">{{$t('merchant.TotalPrice')}}</div>
					<div class="value">{{viewForm.currencySign}}{{viewForm.total?viewForm.total.toFixed(2):'0.00'}}</div>
				</div>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="receptModal = false">Confirm</el-button>
            </span>
        </el-dialog>

        <!-- 评价弹窗 -->
        <el-dialog
            :visible.sync="commentVisible"
            :width="isMobile?'96%':'650px'"
            :before-close="closeComment">
            <template slot="title">
                <div class="dia_title">
                    <i class="iconfont icon-quanbudingdan"></i>
                    Review
                </div>
            </template>
            <div class="comment_container">
                <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="Leave your valuable comments"
                    v-model="reviewForm.review">
                </el-input>
                <p class="score">Score</p>
                <div class="rate">
                    <span class="label">Taste</span>
                    <el-rate
                        v-model="reviewForm.tasteRating"
                        :colors="colors"
                        text-color="#9c23b9"
                        score-template="{value}">
                    </el-rate>
                </div>
                <div class="rate">
                    <span class="label">Speed</span>
                    <el-rate
                        v-model="reviewForm.speedRating"
                        :colors="colors"
                        text-color="#9c23b9"
                        score-template="{value}">
                    </el-rate>
                </div>
                <div class="rate">
                    <span class="label">Service</span>
                    <el-rate
                        v-model="reviewForm.serviceRating"
                        :colors="colors"
                        text-color="#9c23b9"
                        score-template="{value}">
                    </el-rate>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="publish">Publish</el-button>
            </span>
        </el-dialog>

        
        <!-- pending状态提示弹框 -->
        <el-dialog :visible.sync="dialogVisible" :width="isMobile?'96%':'450px'">
            <p>{{pendingPrompt}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">confirm</el-button>
            </span>
        </el-dialog>

        <!-- refund policy弹框 -->
        <el-dialog :visible.sync="refundPolicyVisible" :width="isMobile?'96%':'650px'">
            <div class="content">
                <div class="desc">
                    <h5>Refunds</h5>
                    If you do not get your food from a specific restaurant using our services or if there are missing items which we will confirm from the restaurant as well for better communication purposes, we have a refund policy for the convenience of our customers. If the things mentioned apply to you as a customer, you are eligible for a refund. You will be fully refunded the exact amount you paid to the restaurant and the money will arrive in your bank account in 3-5 working days. If due to any circumstances, you do not get refunded on time, please contact us and we will be more than happy to assist you.
                    <br><br>
                    If you need help with anything else, please contact us through our email, phone or live chat and we will sort out the matter for you in the best possible way that we can.  
                </div><br>
                <div class="desc">
                    <h5>Cancelling or amending orders</h5>
                    As soon as the order has been completed and accepted, you are no longer entitled to change or cancel your order, 
                    this also includes any entitlement to a refund. If for any reason you wish to cancel or amend your order, you can contact the Restaurant Directly. 
                    However, please be aware the Restaurant is not under any obligation to agree and fulfil such requests where they have started processing an order.
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="refundPolicyVisible = false">confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { postGateway } from '@/request';
import { payment } from '@/utils/payment';
export default {
    data() {
        return {
            pages: {
                pageNum: 1,
                pageSize: 5,
            },
            keyword: '',
            total: 0,
            orderList: [],
            loading: false,
            nowRate: 5,
            receptModal: false,
            pageLoading: false,
            viewForm: {
				merchantName: '',
                name: '',
			},
            commentVisible: false,
            colors: ['#aa0bc0','#aa0bc0','#aa0bc0'],
            payMap: {
				'cod': 'Cash',
				'paypal_v2': 'Paypal',
				'stp': 'Stripe',
				'paypal_pro': 'Card'
			},
            userInfo: {
                clientId: ''
            },
            reviewForm: {
                review: '',
                serviceRating: 5,
                speedRating: 5,
                tasteRating: 5,
            },
            merchantId: null,
            orderId: null,
            dialogVisible:false, //pending提示弹窗
            pendingPrompt:"Thank you for placing your order! We're currently awaiting confirmation from the restaurant. Once they accept your order, you'll receive a confirmation email.",
            refundPolicyVisible:false,//refundPolicy提示弹窗
        }
    },
    mounted() {
        this.getData()
        this.getUserData();
    },
    computed: {
        isMobile() {
            return window.innerWidth < 950
        }
    },
    methods: {
        getUserData() {
            postGateway({
                url: '/customerApp/center/getDetail',
                method: 'GET',
                data: {
                    clientId: localStorage.getItem('clientId')
                }
            }).then(res=>{
                this.userInfo = res.data;
                this.loading = false;  
            })
            .catch(err=>{
                this.$message.error(err.msg)
                this.loading = false;  
            })
        },
        //关闭评论
        closeComment(){
            this.commentVisible = false
        },
        //发布评论
        publish() {
            if(!this.reviewForm.review){
                this.$message({
                    message: 'Leave your valuable comments',
                    type: 'warning'
                })
            }
            this.loadingPage();
            postGateway({
                url: '/mtReview/addReview',
                data: {
                    clientId: localStorage.getItem('clientId'),
                    merchantId: this.merchantId,
                    orderId: this.orderId,
                    clientName: this.userInfo.nickName || this.userInfo.firstName,
                    review: this.reviewForm.review,
                    serviceRating: this.reviewForm.serviceRating,
                    speedRating: this.reviewForm.speedRating,
                    tasteRating: this.reviewForm.tasteRating
                }
            }).then(() => {
                this.closeLoading();
                this.$message({
                    message: 'Comment successful',
                    type: 'success'
                })
                this.getData(true);
                this.commentVisible = false
                //回到初始值
                this.reviewForm.review = ''
                this.reviewForm.serviceRating = 5
                this.reviewForm.speedRating = 5
                this.reviewForm.tasteRating = 5
            })
            .catch(()=>{
                this.closeLoading();
                this.$message.info('Net Error')
            })
        },
        // 打开弹窗
        Comment(item){
            if(item.isMobile) {
                return
            }
            this.commentVisible = true
            this.merchantId = item.merchantId
            this.orderId = item.orderId
        },
        // 获取评价
        getReview() {

        },
        $t(str) {
            return str.split('.')[1]
        },
        loadingPage() {
            this.pageLoading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        closeLoading() {
            this.pageLoading.close();
        },
        getDetails(item) {
            this.loadingPage();
			postGateway({
                url: '/customerApp/order/orderDetail',
                method: 'GET',
                data: {
                    orderId: item.orderId * 1
                }
            }).then(res => {
				console.log(res, '订单详情')
				this.viewForm = res.data;
				this.receptModal = true;
                this.closeLoading();
			})
            .catch(()=>{
                this.closeLoading();
                this.$message.info('Net Error')
            })
        },
        goMerchant(item) {
            // this.$message.info('Developing')
           
            this.$router.push({
                path: '/menu-' + item.restaurantSlug
            })
        },
        paynow(item) {
            console.log(item, 'item')
            let pa = {
                orderId: item.orderId,
                amount: item.price,
                merchantId: item.merchantId
            }
            payment(item.paymentType, pa)
        },
        getMoreOrder() {
            if(this.pages.pageSize==3) {
                this.pages.pageSize = 10;
                this.getData(true);
            } else {
                this.pages.pageNum ++;
                this.getData();
            }
        },
        getData(refresh=false) {
            this.loading = true;
            if(refresh) {
                this.pages.pageSize = 10;
                this.pages.pageNum = 1;
            }
            postGateway({
                url: '/customerApp/order/orderList',
                method: 'GET',
                data: {
                    clientId: localStorage.getItem('clientId'),
                    ...this.pages,
                    keyword: this.keyword
                }
            }).then(res=>{
                if(refresh) {
                    this.orderList = [];
                }
                this.orderList = this.orderList.concat(res.data.list)
                this.loading = false;
                this.total = res.data.total;
            })
            .catch(err=>{
                this.loading = false;
                this.$message.info(err.msg)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #09CA6A;

.my-order {
    background: #fafafa;
    border-radius: 30px;
    .cancel-content {
        color: red!important;
    }
    .pending-content {
        color: #f3a75b!important;
    }
    .order-box {
        margin-top: 30px;
        min-height: calc(100vh - 260px);
        overflow-y: auto;
        padding: 20px 0 20px;
        // border-radius: 6px;
        border-radius: 30px;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        .bottom-box {
            width: 100%;
            display: flex;
            margin: 60px auto;
            justify-content: center;
            .more-btn {
                // margin: 60px auto;
                margin: auto 10px;
                height: 52px;
                width: 240px;
                // border-radius: 6px;
                border-radius: 30px;
                background-color: $mainColor;
                color: #fff;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                transition: background-color 0.2s ease-in;
                // box-shadow: 0px 5px 10px #8F9698;
                cursor: pointer;
                &:hover {
                    background-color: #AA0BC0;
                }
            }
            .help-btn {
                margin: auto 10px;
                height: 52px;
                width: 240px;
                border-radius: 30px;
                background-color: $greenColor;
                color:#fff;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                transition: background-color 0.2s ease-in;
                cursor: pointer;
                &:hover {
                    background-color: #08b25d;
                }
            }
        }
        
        .title {
            color: $mainColor;
            font-weight: bold;
            margin: 0 0 20px;
            padding: 30px 40px 0;
            padding-bottom: 10px;
            font-size: 22px;
            position: sticky;
            top: 0;
            z-index: 50;
        }
        .loading-box {
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 40px;
                color: #FF8000;
                font-weight: 600;
            }
        }
        ul {
            // padding-left: 40px;
            li:nth-child(even) {
                background-color: #fff;
            }
        }
        .order-card {
            display: flex;
            white-space: wrap;
            word-break: normal;
            padding-bottom: 10px;
            // border-bottom: 1px solid rgba($color: #a7a5a5, $alpha: 0.5);
            margin-bottom: 10px;
            padding-left: 40px;
            .card-left {
                padding: 0;
                margin-right: 20px;
                .order-number {
                    width: 80px;
                    margin-top: 2px;
                    color: $mainColor;
                    text-align: center;
                    font-weight: bold;
                }
                img {
                    height: 110px;
                    width: 110px;
                    // border-radius: 8px;
                    border-radius: 30px;
                    background: #a7a5a5;
                }
                .status-tag {
                    margin-top: 8px;
                    // border-radius: 4px;
                    border-radius: 30px;
                    // padding: 6px 0;
                    padding: 3px 0;
                    width: 110px;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    background-color: #cf1e07;
                    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
                }
                .status-unpaid {
                    background-color: #FF8000;
                }
                .status-pending {
                    background-color: #FF8000;
                }
                .status-cancel {
                    background-color: #cf1e07;
                }
                .status-accepted {
                    background-color: $greenColor;
                }
                .status-initial_order {
                    background-color: #8F9698;
                }
            }
            .card-center {
                flex: 1;
                .under-line {
                    position: relative;
                    bottom: 1px;
                    font-size: 17px;
                    cursor: pointer!important;
                    text-decoration: underline;
                }
                .name-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .name {
                        padding-top: 10px;
                        font-size: 20px;
                        font-weight: 700;
                        color: #000;
                        margin-bottom: 6px;
                    }
                    .receipt-content {
                        position: relative;
                        bottom: 4px;
                        font-size: 13px;
                        color: #8F9698;
                        cursor: pointer;
                        text-decoration: underline;
                        white-space: nowrap;
                        padding-right: 4px;
                    }
                }
                
                .desc-mobile {
                    display: none;
                }
                .desc {
                    // color: #000;
                    color: #8F9698;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    font-size: 14px;

                    .point {
                        display: inline-block;
                        height: 4px;
                        width: 4px;
                        border-radius: 2px;
                        background-color: #000;
                        margin-left: 8px;
                    }
                }
                .rate-box {
                    // margin-top: 10px;
                    display: flex;
                    align-items: center;
                    :deep(.el-rate__icon) {
                        font-size: 22px;
                        // margin-right: -2px;
                    }

                    // .review-btn {
                    //     color: #8F9698;
                    //     margin-left: 10px;
                    //     font-size: 18px;
                    //     text-decoration: underline;
                    //     cursor: pointer;
                    // }
                    // .img-btn {
                    //     height: 24px;
                    //     width: 24px;
                    //     margin-left: 10px;
                    //     cursor: pointer;
                    //     transition: all 0.2s ease-in-out;
                    //     &:hover {
                    //         transform: scale(1.2);
                    //     }
                    // }
                }
                .cancel-content {
                    color: red;
                }
                .pending-content {
                    color: #f3a75b;
                }
            }
            .card-right {
                padding-right: 40px;
                .btn {
                    height: 42px;
                    width: 180px;
                    // background-color: $greenColor;
                    background-color: $mainColor;
                    font-weight: bold;
                    color: #fff;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    // box-shadow: 0px 5px 10px #8F9698;
                    // border-radius: 8px;
                    border-radius: 30px;
                    cursor: pointer;
                    transition: all .2s ease;
                    &:hover {
                        // background-color: #08b25d;
                        background-color: #8d0d9e;
                    }
                }
                .btn-visit {
                    background-color: $greenColor;
                    &:hover {
                        background-color: #08b25d;
                    }
                }
                .btn-again {
                    background: #AA0BC0;
                    &:hover {
                        background-color: #8d0d9e;
                    }
                }
                .btn-view {
                    background: #6b6a6b;
                }
                .btn-pay {
                    background: #FF8000;
                    &:hover {
                        background-color: #c36507;
                    }
                }
            }
        }
        .review-box {
            width: 175px;
            display: flex;
            text-align: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 30px;
            margin: 0 auto;
            margin-bottom: 20px;
            // margin-top: -40px;
            .review-btn {
                // color: #8F9698;
                margin-left: 10px;
                font-size: 18px;
                text-decoration: underline;
                cursor: pointer;
            }
            .img-btn {
                height: 24px;
                width: 24px;
                margin-left: 10px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
        .card-bottom {
            display: none;
        }
        .empty-block {
            height: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 400px;
                border-radius: 12px;
            }
        }
        .card-color {
            background: #fafafa;
        }
    }

    .comment_container {
        .score {
            padding: 32px 0 10px 0;
            color: #46bf61;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px dashed #d8d8d8;
        }
        .rate {
            display: flex;
            align-items: center;
            padding: 10px 0;
            .label {
            display: inline-block;
            width: 80px;
            margin-right: 12px;
            }
        }
    }
    .el-dialog {
        border-radius: 12px !important;
        .el-dialog__footer {
            .dialog-footer {
            .el-button--primary {
                color: #fff;
                font-weight: bold;
                background-color: $mainColor;
                border-color: $mainColor;
                border-radius: 30px;
            }
            }
        }
    }
    .content {
      margin: 50px auto;
      padding-bottom: 90px;
      .desc {
        padding: 0px 15px;
        color: #000;
        font-size: 14px;
        line-height: 20px;
        font-family: Robot;
        // text-indent: 4px;
        // background-color: #f6f6f6;
        border-radius: 10px;
        h5 {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 10px;
        };
        h6{
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
        };
        ul{
          list-style-type:disc;
          padding: 0px 45px;
        }
      }
    }
}
.form-title {
    font-weight: bold;
}
.order-form {
    .form-line {
        padding: 2px 0;
        display: flex;
        font-size: 14px;
        margin-bottom: 2px;
        .label {
            color: gray;
        }
        .value {
            flex: 1;
            color: #000;
            text-align: right;
            white-space: nowrap;
        }
    }
    .item-empty {
		height: 15px;
	}
	.item-empty::after {
		content: '.';
		opacity: 0;
	}
    .food-box {
		font-size: 13px;
		color: #000;
		// padding: 10px 0;
		border-top: 1px solid gray;
		// border-bottom: 1px solid gray;
		
		.meat-options {
			margin: 10rpx 0 10rpx 0;
			color: #318745;
		}
	}
}
@media screen and (max-width: 950px) {
    .form-title {
        font-weight: bold;
        h2 {
            font-size: 15px;
        }
    }
    .my-order {
        overflow: unset;
        padding: 0;
        background: unset;
        .order-box {
            margin-top: 10px;
            box-shadow: unset;
            padding-top: 10px;
            ul {
                padding-left: 0;
                li {
                    // margin: 0 15px 10px;
                    margin:10px 0px;
                    // padding: 10px;
                    padding: 20px 10px;
                    border-radius: 4px;
                    // background: #fff;
                    // border: 1px solid rgba($color: #a7a5a5, $alpha: 0.5);

                    .order-card {
                        border-bottom: 0px;
                        margin-bottom: 0px;
                        padding-left: 0px;
                        .card-left {
                            justify-content: flex-start;
                            align-items: flex-start;
                            img {
                                height: 80px;
                                width: 80px;
                                // border-radius: 4px;
                                border-radius: 30px;
                            }
                            .status-tag {
                                width: 80px;
                                font-size: 12px;
                            }
                        }
                        .card-center {
                            .name-box {
                                .name {
                                    padding-top: 0;
                                    font-size: 16px;
                                    width: 195px;
                                }
                            }
                            .desc {
                                display: none;
                            }
                            .desc-mobile {
                                display: flex;
                                flex-direction: column;
                                .line {
                                    display: flex;
                                    font-size: 12px;
                                    padding-right: 10px;
                                    color: #000;
                                    margin-top: 2px;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    -webkit-line-clamp: 1;  // 控制展示几行
                                    line-clamp: 1;  // 控制展示几行
                                    -webkit-box-orient: vertical;
                                }
                                .price {
                                    font-size: 16px;
                                    font-weight: bold;
                                    margin-top: 10px;
                                }
                            }
                            .rate-box {
                                // display: none;
                                margin-top: 0px;
                                :deep(.el-rate__icon) {
                                    font-size: 17px;
                                }
                                .review-btn {
                                    display: none;
                                }
                                .img-btn {
                                    display: none;
                                }
                            }
                        }
                        .card-right {
                            display: none;
                        }
                    }
                    
                    .card-bottom {
                        display: flex;
                        // justify-content: flex-end;
                        justify-content:center;
                        .btn {
                            // height: 32px;
                            height: 25px;
                            padding: 0 12px;
                            // background-color: $greenColor;
                            background-color: $mainColor;
                            font-weight: bold;
                            color: #fff;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 0px;
                            border-radius: 16px;
                            margin-right: 6px;
                            margin: 0 15px;
                            cursor: pointer;
                            transition: all .2s ease;
                            &:hover {
                                // background-color: #08b25d;
                                background-color: #8d0d9e;
                            }
                        }
                        .btn-visit {
                            background-color: $greenColor;
                            &:hover {
                                background-color: #08b25d;
                            }
                        }
                        .btn-again {
                            background: #AA0BC0;
                            &:hover {
                                background-color: #8d0d9e;
                            }
                        }
                        .btn-view {
                            background: #6b6a6b;
                        }
                        .btn-pay {
                            background: #FF8000;
                            &:hover {
                                background-color: #c36507;
                            }
                        }
                    }
                    .review-box {
                        margin-top: 20px;
                        margin-bottom: 0px;
                    }
                }
            }
            .bottom-box {
                .help-btn {
                    height: 42px;
                    width: 160px;
                    margin: 0 5px;
                }
                .more-btn {
                    height: 42px;
                    width: 160px;
                    // margin: 20px auto;
                    margin:0 5px;
                }
            }

        }
    }
}
</style>