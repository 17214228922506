<template>
    <div class="checkout" :style="{ 'min-height': innerHeight }">
      <div class="content-box">
        <div class="match-box">
          <div class="break-box">
            <div class="break">
              <i class="el-icon-back" @click="goBack"></i>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click="$router.go(-1)"
                  >Menu</el-breadcrumb-item
                >
                <el-breadcrumb-item>
                  <span style="color: rgb(9, 202, 106)">Check out</span>
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <h2 class="check-title" v-if="token">Check out</h2>
          <h2 class="check-title" v-else>Login To Continue</h2>
          <img
            class="cuise-img"
            src="@/assets/images/cuiseNot.png"
            alt="nofound"
          />
        </div>
      </div>
  
      <div class="main-content">
        <!-- Display a payment form -->
        <form id="card-form">
          <h2 class="title">Card Payment</h2>
          <div class="loading-box" v-if="showLoad">
            <i class="el-icon-loading"></i>
          </div>
  
          <div>
            
          </div>
        </form>
      </div>
  
  
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import { postGateway, postGatewayOther } from "@/request";
  import LoadErrorModal from "@/components/LoadErrorModal.vue"
  export default {
    name: "NotFound",
    components: {
      LoadErrorModal
    },
    metaInfo: {
      meta: ["All Eat "],
      title: "All Eat not found",
    },
    data() {
      return {
        token: localStorage.getItem("token"),
        paypalToken: "",
        merchantId: null,
        orderId: "",
        amount: "",
        innerHeight: "",
        scriptLoading: true,
        message: "vue-btn",
        showToast: false,
        showErr: false,
        errMsg: "Please enter complete information",
        showLoad: true,
        currency: 'GBP',
        showLoadError: false,
        form: {
          cardNumber: "",
          expirationDate: "",
          cvv: "",
        },
      };
    },
    created() {
      let { merchantId, orderId, amount } = this.$route.query;
      this.merchantId = merchantId;
      this.orderId = orderId;
      this.amount = amount;
      this.getGuavaPayUrl();
    },
    mounted() {
      this.innerHeight = window.innerHeight + "px";
    },
    methods: {
    getGuavaPayUrl() {
        // 获取paypal client id
        // 先获取clientid
        postGateway({
          url: `/guavaPay/createOrder`,
          method: "GET",
          data: {
            oId: this.orderId
          },
        })
          .then((res) => {
            console.log(res, "client");
            location.href = res.data?.paymentPageUrl;
            setTimeout(() => {
                this.showLoad = false;
            }, 5000);
          })
          .catch(function (error) {
            this.showLoadError = true;
            this.showLoad = false;
            console.log(error);
          });
      },
      detectBrowser() {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Chrome")!== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
          return "Google Chrome"; // Chrome
        } else if (userAgent.indexOf("Firefox") !== -1) {
          return "Mozilla Firefox"; // Firefox
        } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edge") === -1) {
          return "Apple Safari"; // Safari
        } else if (userAgent.indexOf("Edg") !== -1) {
          return "Microsoft Edge"; // Edge
        } else {
          return "Unknown browser"; // 其他浏览器...（可根据自己需要确定是否新增其他浏览器的判断）
        }
      },
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  $greenColor: rgb(9, 202, 106);
  $mainColor: #aa0bc0;
  .checkout {
    min-height: 100vh;
  
    .content-box {
      position: relative;
      background-image: url("@/assets/images/notfoundBg.png");
      background-size: cover;
      background-position: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 360px;
      .match-box {
        position: relative;
        height: 100%;
      }
      .break-box {
        position: absolute;
        box-sizing: border-box;
        top: 48px;
  
        .break {
          height: 370px;
          height: 44px;
          display: inline-flex;
          align-items: center;
          padding: 20px;
          border-radius: 22px;
          background-color: #0a191e;
          color: #ffffff;
          .el-icon-back {
            color: #2680ed;
            font-size: 22px;
            margin-right: 10px;
            cursor: pointer;
          }
          :deep(.el-breadcrumb__inner) {
            font-weight: 600;
            color: #fff;
          }
          :deep(.el-breadcrumb__separator) {
            color: #fff;
          }
        }
      }
      .check-title {
        position: absolute;
        box-sizing: border-box;
        bottom: 48px;
        font-weight: 700;
        font-size: 70px;
        color: #fff;
        margin: 0;
      }
      .cuise-img {
        height: 259px;
        width: 370px;
        position: absolute;
        z-index: 1;
        right: 6%;
        top: 0px;
      }
      // tips
      .title-tips {
        font-family: "Poppins";
        font-size: 50px;
        color: #fff;
        font-weight: bold;
      }
  
      .title-desc {
        font-family: "Poppins";
        margin-top: 10px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 0.5px;
      }
    }
  
    .page-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      color: #000;
      font-weight: 600;
      margin: 25px 0 10px;
    }
  
    .steps-box {
      margin: 20px auto;
      width: 1600px;
    }
  
    .main-content {
      margin: 20px auto;
      width: 1600px;
      display: flex;
      flex-direction: row;
      justify-content: center;
  
      .section {
        display: flex;
        flex-direction: column;
        margin-right: 3%;
      }
      .section-login {
        width: 924px;
      }
      .section-1 {
        width: 538px;
      }
      .section-2 {
        width: 476px;
      }
      .section-3 {
        width: 693px;
      }
    }
  }
  
  #card-form {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 12px;
    width: 400px;
    .title {
      text-align: center;
      padding-bottom: 20px;
      font-weight: 700;
    }
    .loading-box {
      display: flex;
      justify-content: center;
      font-size: 25px;
    }
    #submit {
      margin-top: 20px;
      background: #5469d4;
      color: #fff;
      font-weight: 600;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      cursor: pointer;
    }
    #card-holder-name {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 12px;
      margin-top: 6px;
      margin-bottom: 16px;
      color: #3a3a3a;
      font-family: helvetica, tahoma, calibri, sans-serif;
      font-size: 17px;
      background: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-weight: normal;
      resize: vertical;
    }
  }
  
  .paypal-button-container {
    width: 100%;
    max-width: 760px;
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
  }
  .card_container {
    width: 100%;
    max-width: 760px;
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
  }
  .card_field {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 16px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
    font-size: 17px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  button {
    border: none;
  }
  input {
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 20px;
  }
  
  @media screen and (max-width: 950px) {
    .checkout {
      min-height: unset!important;
      .content-box {
        height: 48px;
        .match-box {
          width: 100vw;
  
          .check-title {
            font-size: 20px;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            bottom: unset;
          }
          .break-box, .cuise-img {
            display: none;
          }
  
        }
      }
      .main-content {
        width: 100vw;
        #card-form {
          width: 97vw;
          #submit {
            font-size: 20px;
          }
        }
      }
    }
    
  }
  
  </style>
  