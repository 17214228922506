<template>
  <div>
    <div class="back-btn">
            <i class="el-icon-back"></i>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item>
                <span style="cursor: default; font-weight: 600; color: #09ca6a"
                  >Privacy Policy</span
                >
              </el-breadcrumb-item>
            </el-breadcrumb>
    </div>
    <div class="faqs_container">
      <!-- <div class="container">
        <img src="@/assets/policy/faqs_bg.png" alt="" />
      </div> -->
      <div class="content">
        <h4 style="margin-top: 18px; padding:15px;font-family:Robot;">
          Privacy Policy of https://www.alleatapp.com
        </h4>
        <h3
          style="
            font-size: 35px;
            font-weight: bold;
            text-align: center;
            padding: 20px 0px;
          "
        >
          Privacy Policy
        </h3>
        <!-- <h3
          style="
            font-weight: bold;
            text-align: center;
            margin-top: 18px;
          "
        >
          All Eat App Network Technology Incorporated Ltd
        </h3> -->
        <!-- <h4 class="title">Privacy Policy</h4> -->
        <div class="desc">
          Effective date: August 15, 2018 <br /><br />
          All Eat App Network Technology Ltd ("us", "we", or "our") operates the
          https://www.alleatapp.com website and the All Eat App mobile application
          (hereinafter referred to as the "Service").
          <br /><br />
          This page informs you of our policies regarding the collection, use, and
          disclosure of personal data when you use our Service and the choices you
          have associated with that data.
          <br /><br />
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings as
          in our Terms and Conditions.
        </div>
        <h4 class="title">Information Collection And Use</h4>
        <div class="desc">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
          <h5>Types of Data Collected</h5>
          <!-- <h4 class="title">Types of Data Collected</h4> -->
          <!-- <div class="desc"> -->
            <h6>1. Personal Data</h6>
            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data </li>
            </ul>
            <h6>2. Usage Data</h6>
            We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data"). 
            <br><br>
            This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
            <br><br>
            When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
            <h6>3. Tracking & Cookies Data</h6>
            We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
            <br><br>
            Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
            <br><br>
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
            <br><br>
            Examples of Cookies we use:
            <ul>
              <li>
                <span style="font-weight: bold;">Session Cookies.</span> 
                We use Session Cookies to operate our Service.
              </li>
              <li>
                <span style="font-weight: bold;">Preference Cookies.</span>
                We use Preference Cookies to remember your preferences and various settings.
              </li>
              <li>
                <span style="font-weight: bold;">Security Cookies.</span>
                We use Security Cookies for security purposes. 
              </li>
            </ul>
            <h6>4. User Profile Information</h6>
            Our app provides the ion for customers to upload their images on All Eat App. We understand the importance of privacy and strive to protect the personal information associated with these images. When customers choose to upload images, we collect, store, and process this data solely for the purpose of enhancing their user experience within our app, such as providing visual representations of their reviews or recommendations. We do not use these images for any other purposes, such as targeted advertising or sharing with third parties, unless explicitly authorised by the customer or required by law. We take appropriate measures to safeguard these images and ensure their confidentiality. Customers retain ownership and control over their uploaded images and can delete them from our platform at any time. By using All Eat App and choosing to upload images, customers acknowledge and consent to the collection, storage, and processing of their personal information associated with these images, as outlined in this privacy policy.
          <!-- </div> -->
          <!-- <h6>Usage Data</h6>
          <br />
          We may also collect information that your browser sends whenever you
          visit our Service or when you access the Service by or through a mobile
          device ("Usage Data").
          <br />
          This Usage Data may include information such as your computer's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that you visit, the time and date of your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
          <br />
          When you access the Service with a mobile device, this Usage Data may
          include information such as the type of mobile device you use, your
          mobile device unique ID, the IP address of your mobile device, your
          mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
          <br />
          <h6>Tracking & Cookies Data</h6>
          <br />
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
          <br />
          Cookies are files with small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to
          improve and analyze our Service.
          <br />
          You can instruct your browser to refuse all cookies or to indicate when
          a cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Service.
          <br />
          Examples of Cookies we use:
          <br />
          Session Cookies. We use Session Cookies to operate our Service.<br />
          Preference Cookies. We use Preference Cookies to remember your
          preferences and various settings.<br />
          Security Cookies. We use Security Cookies for security purposes.-->
        </div> 
        <h4 class="title">Use of Data</h4>
        <div class="desc">
          <!-- <h6>Legal Requirements</h6><br>
          All Eat App Network Technology Ltd may disclose your Personal Data in the good faith belief that such action is necessary to:
          <br>
          · To comply with a legal obligation<br>
          · To protect and defend the rights or property of All Eat App Network Technology Ltd<br>
          · To prevent or investigate possible wrongdoing in connection with the Service<br>
          · To protect the personal safety of users of the Service or the public<br>
          · To protect against legal liability<br> -->
          All Eat App Network Technology Ltd uses the collected data for various purposes. Please note that we comply with our obligations under the GDPR to safeguard your rights at all times. Below are a few ways in which we process and use your data.
          <br><br>
          <ul>
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
            <li>To provide customer care and support</li>
            <li>To provide analysis or valuable information so that we can improve the Service</li>
            <li>To monitor the usage of the Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
        </div>
        <h4 class="title">Transfer Of Data</h4>
        <div class="desc">
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
          <br /><br />
          If you are located outside United Kingdom and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United Kingdom and process it there.
          <br /><br />
          Your consent to this Privacy Policy followed by your submission of such
          information represents your agreement to that transfer.
          <br /><br />
          All Eat App Network Technology Ltd will take all steps reasonably
          necessary to ensure that your data is treated securely and in accordance
          with this Privacy Policy and no transfer of your Personal Data will take
          place to an organization or a country unless there are adequate controls
          in place including the security of your data and other personal
          information.
        </div>
        <h4 class="title">Disclosure Of Data</h4>
        <div class="desc">
          <!-- <h6>Legal Requirements</h6> -->
          <h5>Legal Requirements</h5>
          All Eat App Network Technology Ltd may disclose your Personal Data in
          the good faith belief that such action is necessary to:
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of All Eat App Network Technology Ltd</li>
            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>To protect the personal safety of users of the Service or the public</li>
            <li>To protect against legal liability</li>
        </ul><br />
        </div>
        <h4 class="title">Security Of Data</h4>
        <div class="desc">
          As a part of data security, we would like to inform you that you can exercise your data protection rights which includes Right to Erasure in which you can ask us to delete your data which is saved with us. We will do so unless we are bound by a compelling reason to retain. The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
        </div>
        <h4 class="title">Service Providers</h4>
        <div class="desc">
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf, to
          perform Service-related services or to assist us in analyzing how our
          Service is used.
          <br /><br />
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
          <br />
          <!-- <h6>Analytics</h6> -->
          <h5>Analytics</h5> 
          We may use third-party Service Providers to monitor and analyze the use
          of our Service.
          <br />
          <ul>
            <li>
              <span style="font-weight: bold;">Google Analytics</span>
            </li>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected to
            track and monitor the use of our Service. This data is shared with other
            Google services. Google may use the collected data to contextualize and
            personalize the ads of its own advertising network.
            <!-- <br /> -->
            For more information on the privacy practices of Google, please visit
            the Google Privacy & Terms web page:
            <a style="color: blue; text-decoration: underline;" href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
        </ul>
        </div>
        <h4 class="title">Links To Other Sites</h4>
        <div class="desc">
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy Policy
          of every site you visit.
          <br /><br />
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </div>
        <h4 class="title">Children's Privacy</h4>
        <div class="desc">
          Our Service does not address anyone under the age of 18 ("Children").
          <br /><br />
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you are
          aware that your Children has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data from
          children without verification of parental consent, we take steps to
          remove that information from our servers.
        </div>
        <h4 class="title">Changes To This Privacy Policy</h4>
        <div class="desc">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
          <br /><br />
          We will let you know via email and/or a prominent notice on our Service,
          prior to the change becoming effective and update the "effective date"
          at the top of this Privacy Policy.
          <br /><br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </div>
        <h4 class="title">Contact Us</h4>
        <div class="desc">
          If you have any questions about this Privacy Policy, please contact us:
          <br />
          <ul>
            <li>By email: info@alleatapp.com</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy-Policy",
  data() {
    return {
      orderId: "",
      fqsInfo: {},
    };
  },
  created() {

  },
  mounted() {
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
$color: #000;
.fw700 {
  font-weight: 700;
  h5 {
    font-size: 20px;
    font-weight: 700;
  }
}
.mb-10 {
  margin-bottom: 10px;
}
.margin16 {
  margin: 16px;
}
.back-btn {
    background: #0a191e;
    height: 36px;
    border-radius: 18px;
    padding: 0 20px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 30px 6% 0;
    .el-icon-back {
      color: rgb(9, 202, 106);
      font-size: 22px;
      margin-right: 10px;
    }
    :deep(.el-breadcrumb__inner) {
      color: #fff;
    }
    :deep(.el-breadcrumb__separator) {
      color: #fff;
    }
  }
.faqs_container {
  width: 1050px;
  min-height: calc(100vh - 230px);
  margin: 0 auto;
  // font-family: "Poppins", sans-serif !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  .container {
    width: 100%;
    img {
      display: inline-block;
      width: 100%;
      height: 306px;
    }
  }
  .content {
    margin: 50px auto;
    padding-bottom: 90px;
    .western {
      strong {
        span {
          font-weight: bold;
        }
      }
    }
    .title {
      padding: 16px 15px;
      color: $color;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 0px;
      i {
        margin-right: 6px;
      }
    }
    .desc {
      padding: 0px 15px;
      color: $color;
      font-size: 14px;
      line-height: 20px;
      font-family: Robot;
      // text-indent: 4px;
      // background-color: #f6f6f6;
      border-radius: 10px;
      h5 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
      };
      h6{
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      };
      ul{
        list-style-type:disc;
        padding: 0px 45px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .faqs_container {
    width: 98%;
    h3 {
      font-size: 24px;
    }
    h6 {
      font-size: 15px;
      font-weight: 600;
      margin: 0;
    }
    .container {
      width: 100%;
      img {
        height: auto;
      }
    }

    .content  {
      padding: 20px;
    }
    
  }
}
</style>
