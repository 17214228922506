<template>
    <div>
        <!-- 抽屉内容 -->
        <el-drawer
            :visible="value"
            :with-header="false"
            direction="btt"
            size="50%"
            :before-close="handleClose">
            
            <!-- 时间 级联 选择器 -->
            <div class="picker-box">
                <div class="drawer-title" >
                    <div @click="closePicker">Cancel</div>
                    <div class="confirm" @click="confirm" >Confirm</div>
                </div>
                <el-cascader-panel v-model="dateTime"
                    style="flex: 1;width: 100%;overflow: hidden;padding-top: 10px;" :options="timeOptions"></el-cascader-panel>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: "DateTimePicker",
        model: {
            prop: 'value',
            handle: 'input'
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            timeOptions: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                dateTime: []
            }
        },
        methods:{
            handleClose() {
                console.log('选择器被关闭')
            },
            closePicker() {
                this.$emit('input', false);
            },
            confirm() {
                console.log('用户点击确认', this.dateTime);
                this.$emit('input', false);
                this.$emit('confirm', this.dateTime)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .picker-box {
        display: flex;
        flex-direction: column;
        height: 100%;

        .drawer-title {
            width: 100%;
            height: 48px;
            min-height: 48px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: rgb(136, 135, 135);

            .confirm {
                color: #09CA6A;
                font-weight: bold;
            }
        }

    }
</style>