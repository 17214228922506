<template>
  <div class="footer">
    <div class="match-box-footer">
      <div class="center-box">
        <section class="left">
          <ul>
            <li>
              <div class="title">
                <i class="el-icon-location-outline" ></i>
                Address
              </div>
              <span class="item">
                <a href="/about.html">
                  {{ merInfo.merchantAddr }}
                  {{ merInfo.currencySign=="￡"?' - UK ': '' }}
                </a>
              </span>
            </li>
            <li>
              <div class="title"> 
                <i class="el-icon-phone-outline"></i>
                Contact us
              </div>
              <div style="color: #fff;">
                <strong>Phone:</strong><a style="color: #02CA69;" :href="'tel:'+merInfo.restaurantPhone"> {{ merInfo.restaurantPhone }}</a><br>
              </div>

            </li>
            <li style="width: 240px;">
              <div class="title">
                <i class="el-icon-alarm-clock" ></i>
                Opening Hours
              </div>
              <div class="time-box" >
                  <div class="tab-box" >
                      <ul>
                          <li v-for="item in tabsList" :key="item.key" :class="['tab-item',{'item-active': item.key==nowTab}]" @click="nowTab=item.key">{{ item.name }}</li>
                      </ul>
                  </div>
                  <div class="opentime-box">
                      <ul>
                          <li v-for="(item,ind) in timeList[nowTab]" :key="item.id">
                              <span class="name" >{{ timeMap[ind] }}</span>
                              <span class="time-line">{{ item.startTime1.replace('false','') }}-{{ item.endTime1.replace('false','') }}</span>
                              <span class="time-line">{{ item.startTime2.replace('false','') }}-{{ item.endTime2.replace('false','') }}</span>
                          </li>
                      </ul>
                  </div>
              </div>
            </li>
            <li>
              <div class="right-box">
                <div class="follow">Follow Us</div>
                <div class="icon-box">
                  <img src="@/assets/icon/insta.svg" alt="ins" @click="goWeb('https://www.instagram.com/alleatapp/')">
                  <img src="@/assets/icon/tiktok.svg" alt="ins" @click="goWeb('https://www.tiktok.com/@alleatuk')">
                  <img src="@/assets/icon/facebook.svg" alt="ins"
                    @click="goWeb('https://www.facebook.com/alleatdelivery')">
                  <img src="@/assets/icon/youyube.svg" alt="ins"
                    @click="goWeb('https://www.youtube.com/@alleatapp5200/videos')">
                  <img src="@/assets/icon/x.png" alt="x" @click="goWeb('https://x.com/alleat_app')">
                </div>
              </div>
            </li>
          </ul>
        </section>
        
      </div>
      <div class="bottom-box">
        <div class="container">
          <div class="copyright">
            &copy; Copyright <strong><span>{{ MerchantConfig.merchantName }}</span></strong>. All Rights Reserved
          </div>
          <div class="credits" style="margin-top: 12px;text-align: center;">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/Happy Days Chinese Takeaway-bootstrap-restaurant-website-template/ -->
            Designed by <a href="https://www.alleatapp.com/">All Eat App</a> |<a href="/sitemap.xml"> Sitemap </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postGateway } from '@/request'
import merchantConfig from '../../merchant.config.js';
const MerchantConfig = require("../../merchant.config.js");
export default {
  name: "app-footer",
  data() {
    return {
      MerchantConfig,
      fullYear: "",
      foodTypeList: [],
      showFoodTypeList: [],
      inputDisabled: true,
      merInfo: {
        merchantAddr: '',
        restaurantPhone: '',
      },
      openTime: {},
      nowTab: 'pickup',
      tabsList: [
          { name: 'Delivery', key: 'delivery' },
          { name: 'Pick-up', key: 'pickup' },
          { name: 'Dine-in', key: 'dinein' },
      ],
      timeMap: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
      ],
      timeList: [],
    };
  },
  mounted() {
    this.fullYear = new Date().getFullYear();
    //分类
    // this.getFoodType();
    this.getMerchantId();
  },
  methods: {
    getOpenTime(){
      postGateway({
          url: '/merchant/merchantOperateTime/getList',
          method: 'GET',
          data: {
              merchantId: this.merchantId
          }
      }).then(res=>{
        this.timeList = res.data;
      })
    },
    getMerchantInfo() {
        let param = {
            merchantId: this.merchantId,
            type: this.transType == 'delivery'?1:this.transType == 'pickup'?2:this.transType == 'dinein'?3: 0,
        }
        postGateway({
            url: '/customerApp/merchantDetail/getMerchantDetail',
            method: 'GET',
            data: param
        })
        .then(res=>{
            this.merInfo = res.data;
            console.log(this.merInfo,'merchantId');
        })
        .catch((err)=>{
            console.log(err,'报错 err')
        })
    },
    getMerchantId() {
      console.log(merchantConfig,'merchantConfig');
      let slug = merchantConfig.merchantMenuUrl.replace('/menu-','');
      postGateway({
        url: "/customerWeb/merchantDetail/getMerchantBySlug",
        method: "GET",
        data: {
            slug
        }
      }).then((res) => {
        this.merchantId = res.data*1
        this.getMerchantInfo();
        this.getOpenTime();
      }).catch(err=>{
        console.log(err,'err');
      })
    },
    contact() {
      document.location.href = `${this.MerchantConfig.merchantDomain}/contact.html`
    },
    getFoodType() {
      postGateway({
        url: "/customerWeb/index/popularCategory",
        method: "GET",
        data: {},
      }).then((res) => {
        this.foodTypeList = res.data;
        this.showFoodTypeList = res.data.splice(0, 4)
        console.log(res, "res");
      });
    },
    showALlCate() {
      let list = [...this.foodTypeList]
      if (this.showFoodTypeList.length > 4) {
        this.showFoodTypeList = list.splice(0, 4)
      } else {
        this.showFoodTypeList = list
      }
    },
    goCate(item) {
      let { cuisineId, cuisineName } = item;
      this.$router.push({
        path: '/cuisine',
        query: {
          cuisineId: cuisineId,
          cuisineName: cuisineName
        }
      })
    },
    contactUs() {
      window.open("https://alleatapp.net/contact-us/");
    },
    earnPoints() {
      window.open(
        "https://alleatapp.net/what-is-the-points-system-in-all-eat/"
      );
    },
    partnerUs() {
      window.open("https://alleatapp.net/become-patner/");
    },
    aboutUs() {
      window.open("/aboutUs");
    },
    careers() {
      window.open("https://alleatapp.net/careers/");
    },
    seedFeedback() {
      window.open("https://alleatapp.net/contact-us/");
    },
    gotoFaqs() {
      this.$router.push("/faqs");
    },
    myAccount() {
      this.$router.push("/accountManagement/personalDetails");
    },
    gotoCookie() {
      this.$router.push("/cookiePage");
    },
    gotoPrivate() {
      this.$router.push("/Privacy-Policy");
    },
    goHelp() {
      this.$router.push("/help");
    },
    goWeb(url) {
      window.open(url)
    },
    gotoBlog() {
      window.open("https://blog.alleatapp.com/");
    },
    // TODO
    gotoPath(item) {
      item
        ? this.$router.push(`/takeaway/nearme/${item.cuisineName}`)
        : this.$router.push(`/takeaway/nearme/all`);
      sessionStorage.setItem("typeId", item ? item.cuisineId : "");
    },
    gotoLocation(item) {
      this.$router.push(`/takeaway/${item}`);
    },
    gotoGplay() {
      window.open(
        "https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat&gl=GB"
      );
    },
    gotoApp() {
      window.open(
        "https://apps.apple.com/gb/app/all-eat-food-delivery-uk/id1603420846"
      );
    },
    gotoTermsAndCond() {
      this.$router.push("/termsConditions");
    },
  },
};
</script>
<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;

.footer {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #212121;

  .match-box-footer {
    .top-box {
      height: 300px;
      border-bottom: 1px solid #424242;
    }

    .top-box,
    .center-box .left {
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 200px;
          display: flex;
          flex-direction: column;
          margin-right: 12px;

          .title {
            font-weight: 700;
            color: #fff;
            font-size: 24px;
            margin-bottom: 12px;
            white-space: nowrap;
          }

          .item {
            font-weight: 400;
            color: #F5F5F5;
            font-size: 12px;
            margin-bottom: 10px;
            opacity: 0.9;

            a {
              color: #F5F5F5;
            }
          }

          .item-btn {
            cursor: pointer;
          }
          .time-box {
            background: #e0e0e0;
            padding: 12px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
            width: 240px;
            .tab-box {
                display: flex;
                flex-wrap: nowrap;
                .tab-item {
                    flex: 1;
                    height: 20px;
                    padding: 0 2px;
                    white-space: nowrap;
                    background-color: rgba($color: #000000, $alpha: 0.2);
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 12px;
                    font-weight: 500;
                    color: #000;
                    cursor: pointer;
                    margin-right: 12px;
                    transition: all ease-in-out .2s;
                    &:hover {
                        background-color: rgba($color: #000000, $alpha: 0.1);
                    }
                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                    i {
                        color: #000;
                        margin-right: 5px;
                        font-size: 20px;
                    }
                }
                .item-active {
                    background-color: #09CA6A;
                    color: #fff;
                    i {
                        color: #fff;
                    }
                    &:hover {
                        background-color: #09CA6A;
                    }
                }
            }
            .opentime-box {
                margin-top: 10px;
                background: #fff;
                border-radius: 16px;
                padding: 12px;
                ul li {
                    height: 34px;
                    border-bottom: 1px #e0e0e0 dashed;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #000;
                    font-size: 12px;

                    .name {
                        flex: 1;
                    }
                    .time-line {
                        margin-left: 10px;
                    }
                }
            }
        }
        }
      }
    }

    .center-box {
      border-bottom: 1px solid #424242;
      display: flex;
      padding: 12px 12px 24px 12px;
      display: flex;
      justify-content: space-around;

      .left {
        // flex: 1;

        ul {
          // width: 650px;

          li {
            margin-right: 24px;

            .title {
              font-size: 20px;
            }

            .item {
              font-size: 15px;
              opacity: 0.9;
            }

            .right-box {

              .follow {
                font-weight: 700;
                font-size: 20px;
                color: #fff;
              }

              .icon-box {
                width: 200px;
                margin-top: 12px;
                display: flex;
                justify-content: space-between;

                img {
                  height: 36px;
                  width: 36px;
                  margin-right: 15px;
                  cursor: pointer;
                }
              }

              .desc {
                margin-top: 30px;
                color: #BBBBBB;
                font-size: 16px;
                font-weight: 700;
                opacity: 0.6;
              }

              .concat-box {
                margin-top: 30px;
                height: 48px;
                width: 100%;
                display: flex;
                align-items: center;

                .input-box {
                  background-color: #424242;
                  border-radius: 4px;
                  margin-right: 10px;
                  flex: 1;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  padding: 0 20px 0 10px;

                  .el-icon-s-comment {
                    font-size: 25px;
                    color: #fff;
                    margin-right: 5px;
                  }
                }

                input {
                  height: 100%;
                  height: 40px;
                  font-size: 16px;
                  flex: 1;
                  background-color: transparent;
                  color: #fff;
                }

                button {
                  height: 40px;
                  border-radius: 4px;
                  width: 100px;
                  font-weight: 700;
                  font-size: 14px;
                  flex-wrap: nowrap;
                  white-space: nowrap;
                  color: #fff;
                  background-color: #09CA6A;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  transition: all .2s ease-in;

                  &:hover {
                    background-color: #10a058;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        // flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;


        .right-box {

          .follow {
            font-weight: 700;
            font-size: 20px;
            color: #F5F5F5;
            opacity: 0.5;
          }

          .icon-box {
            width: 200px;
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            img {
              height: 36px;
              width: 36px;
              margin-right: 15px;
            }
          }

          .desc {
            margin-top: 30px;
            color: #BBBBBB;
            font-size: 16px;
            font-weight: 700;
            opacity: 0.6;
          }

          .concat-box {
            margin-top: 30px;
            height: 48px;
            width: 100%;
            display: flex;
            align-items: center;

            .input-box {
              background-color: #424242;
              border-radius: 4px;
              margin-right: 10px;
              flex: 1;
              height: 40px;
              display: flex;
              align-items: center;
              padding: 0 20px 0 10px;

              .el-icon-s-comment {
                font-size: 25px;
                color: #fff;
                margin-right: 5px;
              }
            }

            input {
              height: 100%;
              height: 40px;
              font-size: 16px;
              flex: 1;
              background-color: transparent;
              color: #fff;
            }

            button {
              height: 40px;
              border-radius: 4px;
              width: 100px;
              font-weight: 700;
              font-size: 14px;
              flex-wrap: nowrap;
              white-space: nowrap;
              color: #fff;
              background-color: #09CA6A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: all .2s ease-in;

              &:hover {
                background-color: #10a058;
              }
            }
          }
        }

      }
    }

    .bottom-box {
      padding: 40px 0 0;
      display: flex;
      color: #fff;
      justify-content: center;
      font-size: 16px;

      a {
        color: #fff;
        font-weight: bold;
      }


      .rights-box {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #fff;
        font-weight: 700;

        img {
          width: 16px;
          height: 18px;
          margin: 0 18px;
        }
      }
    }

  }

}

@media screen and (max-width: 950px) {
  .footer {
    padding: 0;
    background-color: #1F2025;

    .match-box-footer {
      padding: 0 20px;

      .top-box {
        border-bottom: 2px solid #424242;
      }


      .center-box {
        display: flex;
        flex-direction: column;
        .left {
          ul {
            li {
              margin-bottom: 24px;
              margin-top: 24px;
            }
          }
        }
      }

      .bottom-box {
        .rights-box {
          font-size: 15px;
        }
      }

    }
  }
}
</style>
