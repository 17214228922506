<template>
  <div class="order-success">
    <img class="logo" src="@assets/images/skiLogo.png" mode="widthFix" />
    <div class="logo-name">
      <span class="logo-font">All</span>
      <span class="logo-font eat">Eat</span>
    </div>
    <div style="position: relative">
      
      <div class="loading-box">
        <i class="el-icon-loading"></i>
      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  name: "OrderSuccess",
  data() {
    return {
      orderId: "",
      orderCode: "",
      tId: "",
      showLoad: false,
      Refreshtoken: '',
      token: ''
    };
  },
  created() {
    // 负责处理登录页面
    this.orderId = this.$route.query.orderId;
    this.token = this.$route.query.token;
    this.refreshToken = this.$route.query.refreshToken;
    this.device = this.$route.query.device;

    localStorage.setItem(`viva-order`, this.orderId)
    localStorage.setItem('token', this.token)
    localStorage.setItem('refreshToken', this.refreshToken)

    document.title = 'Card payment'
    
    // 标记订单来源
    localStorage.setItem('order-device', this.device)

    // 转发支付请求
    this.$router.push(`/vivaWallet?orderId=${this.orderId}`)
    
  },
  mounted() {
    this.hideDom();
  },
  methods: {
    hideDom() {
      if(this.$route.query.device || localStorage.getItem('order-device')) {
        let header = document.querySelector('.fix-header');
        header.style.display = 'none';
        let footer = document.querySelector('.footer');
        footer.style.display = 'none';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$color: #000;
.order-success {
  padding-top: 40px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
</style>
  