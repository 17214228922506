<template>
  <div class="favorite">
      <!-- Points -->
      <div class="loading-box" v-if="loading">
          <i class="el-icon-loading"></i>
      </div>
      <div class="top" v-if="!loading">
        <div class="title">
          Total Points
        </div>
        <div class="faq"  @click="dialogVisible = true">
          How To used Points
        </div>
        <el-dialog :visible.sync="dialogVisible">
        <p v-html="this.usePointsRule"></p>
        <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false">confirm</el-button> -->
        </span>
        </el-dialog>
      </div>
      <div class="middle" v-if="!loading">
        <div class="points-box">
          <!-- <img src="@/assets/points/circle2.png" class="circle-box1" alt="circle"/>
          <img src="@/assets/points/circle.png" class="circle-box2" alt="circle"/>
          <div class="five-pointed-box">
            <img  src="@/assets/points/five-pointed_star1.png" alt="five-pointed star"/>
            <div class="five-pointed2">
              <img src="@/assets/points/five-pointed_star2.png" alt="five-pointed star"/>
              <img class="right" src="@/assets/points/five-pointed_star2.png" alt="five-pointed star"/>
            </div>
          </div>  -->
          <img src="@/assets/points/point.png" class="circle-box1" alt="circle"/>
          <div class="points">{{ points }}</div>
        </div>
        <div class="content-box">
              <div class="left-content">
                <span class="fix-content">Spend </span>
                <span class="dynamic-content">{{ spend }}</span>
              </div>
              <div class="right-content">
                <span class="dynamic-content">{{ money }}£ </span>
                <span class="fix-content">Saved</span>
              </div>
        </div>
        <div class="invite-content">Get 50 Points to invite friend Now</div>
        <div class="redeem-btn" @click="CopyInvitationCode">Invitation Code: {{ userInfo.referralCode }}</div>
      </div>
      <el-divider v-if="!loading"></el-divider>
      <div class="history-box" v-if="!loading">
        <div class="history-content">Point History</div>
        <div  
          class="record-box"
          v-for="(item, index) in historyArr"
          :key="index">
          <div class="record-left ">
            <img class="record-icon" src="@/assets/points/award.png" alt="award">
            <div>
              <!-- <span  class="record-text-yellow">From </span>
              <span  class="record-text-black"> {{ item.transType }}</span>
              <br> -->
              <span  class="record-text-black">
                 <!-- {{ item.sourceType }} -->
                 {{getReason(item.sourceType)}}
              </span>
            </div>
          </div>
          <div class="record-middle record-text-yellow">
            <span v-if="item.pointsType=='earn'">+ </span>
            <span v-if="item.pointsType=='spend'">- </span>
            {{ item.totalPointsEarn }} Point
          </div>
          <div class="record-right record-text-black">
            {{ item.dateCreated }}
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { postGateway } from '@/request';
export default {
  data() {
      return {
        clientId:"",
        points:null,
        spend:50,
        money:0.50,
        loading:false,
        // inviteCode:'',
        historyArr:[],//积分使用历史
        invitedFriendsArr:[],//被我邀请的好友
        usePointsRule:'',//积分使用规则
        dialogVisible:false, //弹窗是否打开
        userInfo:{},//用户信息
      }
  },
  mounted() {
    // this.clientId = localStorage.getItem("clientId");
    // this.getClientPointsHistory();
    // this.getClientPoints();
  },
  created() {
    this.loading=true;
    this.clientId = localStorage.getItem("clientId");
    console.log(localStorage.getItem("clientId"),'用户信息');
    this.getClientPointsHistory();
    this.getClientPoints();
    // this.getInvitedFriends();
    this.getUsePointsRule();
    this.getClientInfo();
    // this.loading=false;
  },
  methods: {
    //获取当前用户信息
    getClientInfo() {
      this.loading=true;
      postGateway({
        url: '/customerApp/center/getDetail',
        method: 'GET',
        data: {
            clientId: this.clientId
        }
      }).then(res=>{
        // this.iconLoading = false;
        this.userInfo = res.data;
        console.log(res,'用户信息')
        // console.log(res.data.referralCode)
      })
      .catch(err=>{
        this.iconLoading = false;
        console.log(err)
      })
    },
    //获取当前用户积分
    getClientPoints() {
      this.loading=true;
      postGateway({
        url:'/customer/point/queryClientPoint',
        method:'GET',
        data: {
          clientId:this.clientId,
        }
      }).then((res)=>{
        this.points=res.data.totalPoint;
        this.loading=false;
      });
    },
    //获取积分记录
    getClientPointsHistory() {
      this.loading=true;
      postGateway({
        url:'/customer/point/queryList',
        method:'GET',
        data: {
          clientId:this.clientId,
        }
      }).then((res)=>{
        this.historyArr=res.data;
        this.loading=false;
      })
    },
    // 获取使用积分规则
    getUsePointsRule() {
      postGateway({
        url:'/mtCustomPage/getFaq',
        method:'GET',
        data: {
          pageName: 'Rule',
        }
      }).then((res)=>{
        this.usePointsRule=res.data.content;
      })
    },
    // 获取被我邀请的好友
    getInvitedFriends() {
      this.loading=true;
      postGateway({
        url:'/customer/point/getSubClient',
        method:'GET',
        data: {
          clientId:this.clientId,
        }
      }).then((res)=>{
        this.invitedFriendsArr=res.data;
        this.loading=false;
      })
    },
    //sourceType显示
    getReason(source) {
				let str = 'Unknown';
				switch(source) {
					case 'friend':
						str = 'Friend order'
					break;
					case 'order':
						str = 'Your order'
					break;
					case 'invite':
						str = 'Invite friend'
					break;
					case 'exchange':
						str = 'Exchange'
					break;
					default: 
					break;
				}
				
				return str;
		},
    //复制邀请码
    CopyInvitationCode() {
      navigator.clipboard.writeText(
        "Invitation Code: "+this.userInfo.referralCode
      ).then(() => {        
        this.$message.success('Copy Success')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.favorite {
  // height: calc(100vh - 260px);
  min-height: 1300px;
  padding: 0px  20px;
  // border-radius: 6px;
  border-radius: 30px;
  // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
  margin-top: 50px;
  background: #ffffff;
  .loading-box {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        font-size: 40px;
        color: #FF8000;
        font-weight: 600;
    }
  }
  .top {
    position: relative;
    .title {
      color: #aa0bc0;
      // padding: 50px;
      padding: 30px 50px 35px 50px;
      text-align: center;
      font-size: 32px;
      }
    .faq {
      position: absolute;
      color: #aa0bc0;
      font-size: 18px;
      right:20px;
      top:40px;
      cursor: pointer;
    }
  }
  .middle {
      width: 100%;
      text-align: center;
      // height: 500px;
      .points-box {
        margin-top: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        // flex-direction: column;
        align-items: center;
        .circle-box1 {
          // margin-top: 20px;
          z-index: 1;
          // position: relative;
        }
        .circle-box2 {
          z-index: 2;
          position: absolute;
          margin-top:-28px;
        }
        .five-pointed-box {
          position: absolute;
          // display:flex;
          // flex-direction: column;
          align-items: center;
          z-index:3;
          margin-top: -160px;
          .five-pointed2 {
            margin-top: -34px;
            // border:solid red($color: #000000);
            display: flex;
            .right {
              margin-left:30px ;
            }
          }
        }
        .points {
          color:#09ca6a;
          font-size: 65px;
          text-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: Cairo;
          // margin-top: 15px;
          font-weight: bold;
          z-index:4;
          position: absolute;
        }
      }
      .content-box {
        // font-family: Cairo;
        display: flex;
        flex-direction: row;
        justify-content: center;
        // margin-top:75px;
        .left-content {
          margin: 0px 30px;
        }
        .right-content {
          margin: 0px 18px;
        }
        .fix-content {
          color: #000000;
          font-size: 20px;
          font-weight: bold;
        }
        .dynamic-content {
          color: #aa0bc0;
          font-size: 20px;
        }
      }
      
      .invite-content {
        font-size: 30px;
        margin-top: 40px;
        text-align: center;
      }
      .redeem-btn {
        margin-top: 20px;
        // color: #000000;
        font-size: 30px;
        font-weight: 700;
        // background-color: #09ca6a;
        // border-radius: 45px;
        padding: 15px 30px;
        cursor: pointer;
      }
  }
  .history-box {
    display: flex;
    flex-direction: column;
    // margin-top: 20px;
    padding: 0px 20px;
    align-items: flex-start;
    padding-bottom: 20px;
    .history-content {
      font-size: 30px;
      color: #aa0bc0;
      margin-bottom: 10px;
    }
    .record-box {
      width:1000px;
      // height: 50px;
      margin:10px 0px;
      padding: 10px auto;
      display: flex;
      flex-direction: row;
      border-radius: 30px;
      background: rgba(252, 246, 193, 0.74);
      .record-left {
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 500px;
        .record-icon {
          width: 28px;
          height: 28px;
          margin: 10px;
        }
      }
      .record-middle {
        padding: 15px;
        flex: 1;

      }
      .record-right {
        padding: 15px;
        width: 190px;
      }
      .record-text-yellow {
        color: #FB8500;
        font-size: 18px;
      }
      .record-text-black {
        color:#000;
        font-size: 18px;
      }

    }
  }
}
@media screen and (max-width:950px) {
  .favorite {
    padding: 0px 5px;
    min-height: 1000px;
    .top {
      .title {
        padding:30px 0px;
        font-size: 27px;
      }
      .faq {
        font-size: 12px;
        right: 5px;
        top:30px;
        width:66px;
      }
      :deep(.el-dialog) {
        width: 90%;
      }
    }
    .middle {
      text-align: center;
      .points-box {
        margin-top: 15px;
        .circle-box1 {
          width:232px;
          height:220px
        }
        .points {
          font-size: 55px;
        }
      }
      .invite-content {
        font-size: 25px;
        margin-top: 20px;
      }
      .redeem-btn {
        font-size: 25px;
        margin-top: 5px;
      }
    }
    .history-box {
      padding: 0px 10px;
      .history-content {
        font-size: 25px;
        margin-bottom: 5px;
      }
      .record-box {
        width: 100%;
        .record-left {
          width:35%;
          padding: 0px;
          .record-icon {
            width:20px ;
            height: 20px;
          }
        }
        .record-middle {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .record-right {
          width: 31%;
          text-align: end;
          margin-right: 10px;
        }
        .record-text-black {
          font-size: 12px;
          padding: 15px 0px;
        }
        .record-text-yellow {
          font-size: 12px;
          padding: 15px 0px;
          text-align: center;
        }
      }
    }
    // .history-box {
    //   .record-box {
    //     width:95%;
    //     display: flex;
    //     flex-direction: column;
    //   }
    // }
  }
}
</style>