<template>
    <div class="category">
        <div class="off-box" v-if="info.freeDeliveryAbovePrice || info.merchantOffs.length">
            <div class="off-title">
                <i class="el-icon-s-ticket"></i>
                <span>Today Discount</span>
            </div>
            <div class="discount-box" v-if="info.freeDeliveryAbovePrice">
                <i class="iconfont icon-waimai"></i>
                <span>
                    No delivery fee for orders over ￡ {{ info.freeDeliveryAbovePrice?info.freeDeliveryAbovePrice.toFixed(2):'-' }}
                </span>
            </div>
            <div class="discount-box" v-for="item in info.merchantOffs" :key="item.id" >
                <i class="el-icon-price-tag"></i>
                <span>{{ getDiscountStr(item) }}</span>
            </div>
            <div style="flex:1;"></div>
            
        </div>
        <div class="cate-search" >
            <i class="el-icon-search"></i>
            <input type="text" placeholder="Search" @keyup.enter="searchFood" v-model="keywords" :clearable="true">
            <button v-if="keywords && !showSearchCover" @click="searchFood" class="search-btn" >Search</button>
            <button v-if="keywords && showSearchCover" @click="clearSearchResult" class="search-btn clear-btn" >Clear</button>
        </div>
        <div class="cate-box">
            <div v-for="item in categoryList"  :key="item.catId" :class="['cate-item',{'now-cate': item.catId==value}]" 
                @click="chooseCateId(item)">
                <span>{{ item.categoryName }}</span>
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { postGateway } from '@/request'

export default {
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        categoryList: {
            type: Array,
            default: () => []
        },
        value: {
            type: [String, Number],
            default: () => ''
        },
        info: {
            type: Object,
            default: () => {
                return {
                    freeDeliveryAbovePrice: '',
                    merchantId: null,
                    merchantOffs: [],
                    currencySign: ''
                }
            }
        }
    },
    computed: {
        currencySign() {
            return this.info.currencySign || ''
        }
    },
    watch: {
        'keywords': function(val) {
            if(!val) {
                this.clearSearchResult(val);
            }
        }
    },
    data() {
        return {
            keywords: '',
            resultList: [],
            showSearchCover: false,
        }
    },
    mounted() {

    },
    methods: {
        // 获取完整折扣内容
		getDiscountStr (disc) {
            let str = '';
            if( disc &&  disc.type == 'discount') {
				str = `${disc.percent} % OFF for orders above ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount}`
			}
			if( disc &&  disc.type == 'freeItem') {
				str = `${disc.freeItemNames} for orders above ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount}`
			}
			
            // 中文内容
			// if(this.language == 'cn') {
			// 	// 中文
			// 	if( disc && disc.type == 'discount') {
			// 		str = `满 ￡${disc.amount?disc.amount.toFixed(2):disc.amount} 享 ${disc.percent}% 折扣`;
			// 	}
			// 	if( disc &&  disc.type == 'freeItem') {
			// 		str = `满 ￡${disc.amount?disc.amount.toFixed(2):disc.amount} 赠 ${disc.freeItem}`
			// 	}
			// }
			return str;
		},
        searchFood() {
            this.$emit('searchResult', 'loading')
            postGateway({
                url: '/customerWeb/merchantDetail/getMerchantFood',
                method: 'GET',
                data: {
                    merchantId: this.info.merchantId,
                    // catId: '26838',
                    keyword: this.keywords
                }
            }).then(res=>{
                console.log(res,'搜索食品')
                this.showSearchCover = true;
                this.$emit('searchResult', res.data)
            })
            .catch(err=>{
                this.$message.error('Net Error')
                this.$emit('searchResult', [])
                this.clearSearchResult();
                console.log(err,'err');
            })
        },
        clearSearchResult() {
            this.showSearchCover = false;
            this.keywords = '';
            this.$emit('clearSearchResult','')
        },
        chooseCateId(item) {
            this.$emit('input', item.catId)
            // 滚动到指定内容区域
            let dom = document.querySelector('#catId-'+item.catId);
            const scrollTop = (document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop)/2;
            let target = dom.offsetTop + 650;
            console.log(dom.offsetTop, scrollTop , 'dom')
            if(window.innerWidth < 950) {
                target = dom.offsetTop + 470 ;
            }
            document.body.scrollTop = document.documentElement.scrollTop  = target;
            setTimeout(() => {
                this.$nextTick(()=>{
                    this.$emit('input', item.catId)
                })
            }, 500);
        },
    }
}
</script>
<style lang="scss" scoped>
    $greenColor: #26d07c;
    .category {
        width: 360px;
        .off-box {
            min-height: 180px;
            padding-bottom: 20px;
            background-color: #D61409;
            border-radius: 8px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .off-title {
                font-size: 24px;
                font-weight: 800;
                padding-top: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                padding-left: 20px;

                i {
                    color: #fff;
                    font-size: 28px;
                    margin-right: 10px;
                }
            }
            .discount-box {
                margin-top: 10px;
                background-color: rgba(0, 0, 0, 0.25);
                padding: 10px;
                color: #fff;
                font-weight: 800;
                white-space: pre-wrap;
                i {
                    margin-right: 6px;
                    font-size: 20px;
                }
            }
        }
        .cate-box {
            max-height: calc(100vh - 300px);
            overflow: auto;
            &::-webkit-scrollbar {
                width: 6px;
            }
        }
        .cate-search {
            margin: 15px 0;
            height: 48px;
            display: flex;
            align-items: center;
            background-color: #fff;
            box-shadow: 0px 3px 5px #8F9698;
            border-radius: 4px;
            padding: 0 10px 0 20px;

            i {
                font-size: 20px;
                color: #AA0BC0;
            }

            input {
                background-color: #fff;
                padding-left: 10px;
                font-size: 18px;
                font-weight: 600;
                color: #0A191E;
            }
            .search-btn {
                height: 40px;
                width: 100px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: bold;
                font-size: 16px;
                background-color: #AA0BC0;
                cursor: pointer;
                transition: all ease-in .2s;
                &:hover {
                    background-color: #6c30a7;
                }
            }
            .clear-btn {
                background-color: rgb(137, 134, 134);
                &:hover {
                    background-color: #575658;
                }
            }
        }
        .cate-item {
            padding: 12px 20px;
            font-size: 15px;
            font-weight: bold;
            background-color: #fff;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
            cursor: pointer;
            display: flex;
            align-items: center;
            font-family: "Source Sans Pro", sans-serif;
            // transition: all ease-in-out .1s;
            &:hover {
                background-color: #d3d0d0;
            }
            span {
                flex: 1;
                word-break: normal;
                white-space: pre-wrap;
            }
            i {
                color: #000;
                font-weight: 600;
            }
        }
        .cate-item:nth-child(1) {
            border-radius: 4px 4px 0 0;
        }
        .now-cate {
            background-color: #e8e7e7!important;
            color: #000;
        }
    }

    // 手机相关布局
    @media screen and (max-width: 950px) {
        .category {
            width: auto;
            .off-box, .discount-box, .cate-search {
                display: none;
            }
            .cate-box {
                display: flex;
                width: 100vw;
                // overflow: auto;
                .cate-item {
                    font-size: 13px;
                    padding: 8px 10px;
                    i {
                        display: none;
                    }
                    span {
                        white-space: nowrap;
                    }
                }
                .now-cate {
                    background: $greenColor!important;
                    color: #fff;
                    border-radius: 4px;
                    margin-left: 10px;

                    i {
                        display: inline-block;
                        color: #fff;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

</style>