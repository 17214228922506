import { render, staticRenderFns } from "./MerchantBox.vue?vue&type=template&id=d6be9fcc&scoped=true"
import script from "./MerchantBox.vue?vue&type=script&lang=js"
export * from "./MerchantBox.vue?vue&type=script&lang=js"
import style0 from "./MerchantBox.vue?vue&type=style&index=0&id=d6be9fcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6be9fcc",
  null
  
)

export default component.exports