<template>
  <div class="faqs-box">
    <!-- 文字内容 -->
    <!-- <div class="flex-center title-two">FAQs</div> -->
    <div class="flex-center title-there">Frequently Asked Questions</div>
    <div class="flex-center">
     
    </div>

    <!-- card -->
    <div class="flex-center" style="margin-top: 10px">
      <div class="card card-box">
        <div v-for="(item, index) in titles" :key="index">
          <div class="flex-between" style="padding: 12px 0">
            <div class="faqs-title">{{ item }}</div>
            <div class="add" @click="openItem(index)">
              <!-- <img
                style="height: 100%; width: 100%"
                src="@/assets/contact/add.png"
                alt="people"
              /> -->
              <i class="el-icon-remove" v-if="openList.includes(index)" ></i>
              <i class="el-icon-circle-plus" v-else></i>
              
            </div>
          </div>
          <div class="desc" v-if="openList.includes(index)">{{ responseTip[index] }}</div>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: [
        "Do you deliver to my postcode?",
        "Can I place an order for collection?",
        "Can I order through live chat?",
        "How much do you charge for delivery? ",
      ],
      responseTip: [
        'You can browse nearby takeaways by typing the postcode in our app or the website. Each restaurant will show a delivery distance in miles which will be the radius within which they deliver. ',
        'Yes, you can opt for collection on our app or website. Just select the collection option while placing your order. You can expect to pick up your order 15-20 mins after placing the order for collection.',
        'Live chats don’t accept orders. All takeaway orders can be placed through the All Eat app or on All Eat website.',
        'The delivery charges may vary between restaurants which you can view while placing an order. Some takeaways offer free delivery on a minimum amount of orders.'
      ],
      openList: [

      ]
    };
  },
  methods: {
    openItem(index) {
      if(!this.openList.includes(index)) {
        this.openList.push(index)
      } else {
        let ind = this.openList.indexOf(index)
        this.openList.splice(ind, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.faqs-box {
  margin-top: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .card {
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
    border-radius: 24px;
  }
  .title-one {
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #121212;
    margin-top: 150px;
    font-size: 61px;
    line-height: 72px;
  }
  .content-one {
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #656565;
    margin-top: 40px;
    font-size: 18px;
    line-height: 30px;
  }
  .card-contact {
    margin-top: 4px;
    .form {
      padding: 70px 60px;
      width: 560px;
      height: 500px;
      border-radius: 24px 0px 0px 24px;
      :deep(.el-form-item__label) {
        @extend .title;
        line-height: 10px;
      }
      :deep(.el-input__inner) {
        background: #ffffff;
        border: 1px solid #ededed;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        border-radius: 10px;
      }
      :deep(.el-textarea__inner) {
        background: #ffffff;
        border: 1px solid #ededed;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        border-radius: 10px;
        min-height: 80px !important;
      }
      :deep(.el-button--success) {
        background: #09ca6a;
        border: 1px solid #09ca6a;
        border-radius: 16px;
        height: 64px;
      }
    }
    .details {
      padding: 58px 60px;
      width: 500px;
      height: 500px;
      background: #fafafa;
      border-radius: 0px 24px 24px 0px;
      .title {
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        color: #121212;
        font-size: 23px;
        line-height: 34px;
      }
      .content {
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #656565;
        font-size: 18px;
        line-height: 30px;
      }
      .title2 {
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        color: #121212;
        font-size: 18px;
        line-height: 20px;
      }
      .text {
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #656565;
        margin-top: 10px;
        font-size: 18px;
        line-height: 30px;
        color: #AA0BC0;
      }
      .img-card {
        width: 44px;
        height: 44px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .title-two {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #121212;
    margin-top: 100px;
    font-size: 18px;
    line-height: 20px;
    color: #AA0BC0;
  }
  .title-there {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #121212;
    font-size: 44px;
    line-height: 54px;
    margin-top: 35px;
  }
  .content-two {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #656565;
    // width: 600px;
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
  }
  .content-two-mobile {
    display: none;
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .faqs-title {
      font-size: 24px;
      color: #212;
      font-weight: 700;
    }
  }
  .card-box {
    margin-top: 30px;
    width: 1010px;
    padding: 75px 75px;
    font-size: 24px;
    .add {
      width: 40px;
      height: 40px;
      cursor: pointer;
      color: #aa0bc0;
      font-size: 30px;
      .el-icon-remove {
        color: #a19b9b;
      }
    }
    .title {
      font-size: 24px;
    }
    .desc {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 950px) {
  .faqs-box {
    // zoom: 0.4;
    // width: 100vw;
    margin: 0 auto;
    .title-two {
      font-size: 30px;
    }
    .title-there {
      margin-top: 50px;
      font-size: 28px;
    }
    .content-two {
      display: none;
    }
    .content-two-mobile {
      display: block;
      font-size: 35px;
      font-weight: 500;
      text-align: center;
      line-height: 40px;
      margin-top: 30px;
      padding: 0 40px;
      color: #827E7E;
    }
    .flex-between {
      .faqs-title {
        font-size: 38px;
      }
    }
    .card-box {
      width: 100%;
      padding: 20px;
      .desc {
        // width: 100vw;
      }
      .faqs-title {
        font-size: 20px;
      }
      .add {
        // min-width: 60px;
      }
    }
  }
}
</style>
