<template>
  <div class="order-success">
    <img class="logo" src="@assets/images/skiLogo.png" mode="widthFix" />
    <div class="logo-name">
      <span class="logo-font">All</span>
      <span class="logo-font eat">Eat</span>
    </div>
    <div style="position: relative">
      <div class="logo-tips">Thank You</div>
      <div class="order-desc" >Your order has been placed.</div>
      <div class="order-info" v-if="orderId">
        Reference
        <span style="margin-left: 5px" class="order-no">#{{ orderId }}</span>
      </div>
      <div class="loading-box" v-if="showLoad">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <template v-if="!isIos">
      <div class="btn" @click="goOrderList" v-if="!showLoad && isApp">View Order List</div>
      <div class="btn" @click="goOrderList" v-else-if="!showLoad">View Order List</div>
    </template>

    <div class="ios-back-tips" v-if="isIos && !showLoad">
      <i class="el-icon-top down-up" ></i> 
      <div class="tips">Back to All Eat.</div>
    </div>

    <div class="download-box-info" v-if="!isApp">
      <img src="@/assets/images/order_tips_download.jpg" alt="">
      <div class="download-box" >
        <span class="btn2">
            <a href="https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat" target="_blank"></a>
        </span>
        <span class="btn2">
            <a href="http://itunes.apple.com/cn/app/id1603420846?mt=8" target="_blank"></a>
        </span>
      </div>
    </div>
  </div>
</template>
  
<script>
import { postGateway } from "@/request";
import { loadCustomScript } from "@paypal/paypal-js";
export default {
  name: "OrderSuccess",
  data() {
    return {
      orderId: "",
      orderCode: "",
      tId: "",
      showLoad: false,
      isApp: false,
      isIos: false,
      payType: '',
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;

    if(this.$route.query.payType) {
      this.payType = this.$route.query.payType;
    }

    // 来自 app 的订单回调
    if(this.$route.query.device || localStorage.getItem('order-device')) {
        this.isApp = true;
        this.isIos = (this.$route.query.device == 'ios' || localStorage.getItem('order-device') == 'ios') && this.payType != 'trustPayment';
        localStorage.removeItem('order-device')
        loadCustomScript({
            url: "https://www.alleatapp.com/maps2/js/js.cdn.aliyun.dcloud.net.cn_dev_uni-app_uni.webview.1.5.2.js"
        }).then(()=>{
            
        })
        .catch(err=>{
            console.log(err,' script laod error.')
            this.$message.warning('Net error.')
        })
    }

    // 来自app的回调并且不需要 处理状态
    if(this.$route.query.device && this.$route.query.dontCallback == 1) {
        this.showLoad = false;
        this.isApp = true;
        return
    }

    if (this.$route.query.orderCode) {
      this.orderCode = this.$route.query.orderCode;
      this.tId = this.$route.query.tId;
      // 是来自 viva wallet 的支付
      this.callbackViva();
    }
  },
  mounted() {
    this.hideDom();
    this.uploadError();
  },
  methods: {
    uploadError() {
      try {
        postGateway({
          url: '/order/redirect/updateOrderPage',
          method: "POST",
          data: {
              orderId: this.orderId,
              orderPage: "orderSuccess",
          }
        }).then(() => {})
          .catch((err) => {
            console.log("数据上报失败",err);
          });
          
      } catch (e) {
        console.log("数据上报错误处理");
      }
    },
    hideDom() {
      if(this.$route.query.device || localStorage.getItem('order-device')) {
        let header = document.querySelector('.fix-header');
        header.style.display = 'none';
        let footer = document.querySelector('.footer');
        footer.style.display = 'none';
      }
    },
    goOrderList() {
      if(this.isApp) {
        if(window.uni) {
            window.uni.switchTab({
              url: "/pages/orders/orders?type=pay"
            })
        }
        return
      }
      this.$router.push({
        path: `/usercenter?orderId=${this.orderId}&type=orderDone`,
      });
    },
    // 更改订单状态
    callbackViva() {
      this.showLoad = true;
      postGateway({
        method: "GET",
        // url: "/viva/orderCheckTest",  // TODO test
        url: "/viva/orderCheck",
        data: {
          orderCode: this.orderCode,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.orderId = res.data.orderId;
            this.$message.success("Pay Success.");
            this.showLoad = false;
          } else {
            this.$router.push({
              url: "/payFail",
            });
          }
        })
        .catch(() => {
          this.showLoad = false;
          this.$message.error("Net Error.");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$color: #000;
.order-success {
  padding-top: 40px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .download-box-info {
    margin-top: 20px;
    position: relative;
    img {   
      width: 400px;
    }
    .download-box {
        position: absolute;
        z-index: 20;
        height: 100px;
        width: 100%;
        bottom: 5px;
        display: flex;
        .btn2 {
          flex: 1;
          cursor: pointer;
          a {
              display: block;
              height: 100%;
              width: 100%;
          }
      }
    }

  }

  .ios-back-tips {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    left: 10px;
    
    .el-icon-top {
      font-size: 40px;
      font-weight: bold;
      color: #000;
    }
    .tips {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .down-up{
    animation-name: downUp;
    animation-duration: .8s;
    animation-timing-function: cubic-bezier(0.250, 0.010, 0.120, 0.995);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  @keyframes downUp {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 8px);
    }
  }

  .loading-box {
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 25px;
    background: #fff;
    i {
      height: 20px;
      width: 20px;
    }
  }
  .logo {
    height: 100px;
    width: 100px;
  }
  .logo-name {
    .logo-font {
      font-size: 30px;
      font-weight: bold;
      color: #aa0bc0;
    }

    .eat {
      margin-left: 8px;
      color: #26d07c;
    }
  }
  .logo-tips {
    color: #26d07c;
    font-size: 30px;
    margin-top: 5px;
    text-align: center;
  }

  .order-desc {
    color: #333;
    font-size: 24px;
    margin-top: 5px;
    text-align: center;
  }

  .order-info {
    margin-top: 8px;
    font-size: 45px;
    color: #222;
    text-align: center;

    .order-no {
      color: #000;
    }
  }

  .btn {
    cursor: pointer;
    height: 48px;
    box-sizing: border-box;
    padding: 6px 80px;
    background-color: #aa0bc0;
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 3px 6px, rgba(0, 0, 0, 0.53) 0px 3px 6px;
  }
}

@media screen and (max-width: 950px) {
  .order-success {
    min-height: 400px;
    display: flex;
    justify-content: center;
    .order-info {
      margin-top: 8px;
      font-size: 40px;
      white-space: nowrap;
      color: #222;

      .order-no {
        color: #000;
      }
    }
  }
}
</style>
  