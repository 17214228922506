<template>
  <div>
    <div class="box2">
      <div class="left">
        <img
          style="height: 100%; width: 100%"
          src="@/assets/about/people2.png"
          alt="people"
        />
      </div>
      <div class="right">
        <div class="title">0% Commission</div>
        <div class="content">Why 0% commission policy?</div>
        <div class="footer">
          Being in the UK for more than 2 decades, All Eat’s founder Ruiyou Lin
          wanted to show his support and affection to the local communities. He
          realised that charging exorbitant commissions from local restaurants
          was not reasonable. Plus, restaurants have to print multiple versions
          of the same menu displaying varying prices, leading to a significant
          amount of paper wastage. The idea behind lifetime 0% commission policy
          is to make UK restaurants grow and prosper together without having
          negative impact on the environment. It’s all about doing business for
          a good cause. No gimmick, no frills!!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box2 {
  display: flex;
  padding: 60px 100px;
  justify-content: center;

  .left {
    width: 640px;
    height: 640px;
    border-radius: 50%;
    overflow: hidden;
  }
  .right {
    margin-left: 100px;
    padding-top: 140px;
    .title {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      /* or 111% */

      display: flex;
      align-items: center;
      letter-spacing: 1.08px;
      text-transform: uppercase;

      color: #AA0BC0;
    }
    .content {
      width: 460px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 54px;
      /* or 123% */

      display: flex;
      align-items: center;

      color: #121212;
    }
    .footer {
      margin-top: 20px;
      width: 460px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      display: flex;
      align-items: center;

      color: #656565;
    }
  }
}
@media screen and (min-width:901px)and (max-width:1350px) {
      .box2{
        padding: 3% 5%;
        display:flex;
        justify-content:center;
        align-items:center;
        .left{
          width: 70%;
          height: 70%;
        }
        .right{
          flex-direction: column;
          margin-top:0px;
        }
        }
      
      
    }
@media screen and (max-width:900px) {
      .box2{
        width:100%;
        height:100%;
        padding: 5% 7%;
        flex-direction: column;
        display:flex;
        justify-content:center;
        align-items:center;
        .left{
          width: 70%;
          height: 70%;
        }
        .right{
          flex-direction: column;
          margin-left: 0%;
          width: 100%;
          height: 100%;
          align-items:center;
          margin-top: -10%;
          .content{
            width: 100%;
            font-size:2.1rem;
            line-height: 40px;
            margin-top: 20px;
          }
          .footer
          {
            width: 100%;
          }
        }
       
        }
      
      
    }
</style>
