<template>
  <div id="app">
    <Header v-if="!$route.meta.noneHeader" :noFixedHeader="$route.meta.noFixedHeader" />
    <div class="main-content">
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade" mode="out-in">
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </transition>
    </div>
    <Footer />
    <CooikeDiv />
    <JudgeScreenMask></JudgeScreenMask>
    <CustomerService></CustomerService>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CooikeDiv from "./components/CooikeDiv.vue";
import JudgeScreenMask from "@/components/JudgeScreenMask.vue";
import CustomerService from "./components/CustomerService.vue";
import { judgeScreen } from "@/mixins/judgeScreenH5";
import { getUuid } from "@/utils/app.js"
export default {
  name: "App",
  components: {
    Header,
    Footer,
    CooikeDiv,
    JudgeScreenMask,
    CustomerService
  },
  mixins: [judgeScreen],
  created() {
    let token = localStorage.getItem('token');
    let clientId = localStorage.getItem('clientId');
    let aeClient = localStorage.getItem('aeClient');
    if(token && !clientId && aeClient) {
      localStorage.setItem('clientId', aeClient)
    }
    if(!localStorage.getItem('DeviceId')) {
      localStorage.setItem('DeviceId', getUuid())
    }


  },
  data() {
    return {
      transitionName: ''
    }
  }
};
</script>

<style lang="scss">
// 滚动条样式
::-webkit-scrollbar {
  width: 4px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  background: #26d07c;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background: #fff;
  -webkit-box-shadow: none;
}

// 默认加载icon样式处理
.el-icon-loading {
  font-size: 18px;
  color: #aa0bc0;
  font-weight: bold;
}

.child-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(50px, 0);
  transform: translate(50px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-50px, 0);
  transform: translate(-50px, 0);
}
html {
  margin: 0;
  scroll-behavior: smooth;    
}
body {
  margin: 0;
  scroll-behavior: smooth;  
}
button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
}
* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
  word-break: break-word;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #232323;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
textarea {
  outline: none;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  color: #232323;
}

textarea {
  resize: none;
  overflow: auto;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .6s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  transition: all ease-in-out .2s;

  .main-content {
    min-height: 600px;
  }
  
}
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-cascader-menu{
  max-height: 400px;
}
// 默认宽度盒子
.match-box {
  width: 1600px;
  margin: 0 auto;
}
.mobile-show {
  display: none;
}
.timer-pop {
  .el-cascader-panel .el-cascader-menu .el-cascader-menu__wrap .el-scrollbar__view{
      .el-cascader-node .el-cascader-node__label {
        font-weight: bolder!important;
        // color: #000!important;
    }
  }
}

// to fix 在ios设备中，el-select组件下拉框，点击2次才能选中问题。
.el-scrollbar {
  .el-scrollbar__bar {
      opacity: 1 !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1550px) {
  // 默认宽度盒子
  .match-box {
    width: 1400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 950px) {
  html,body {
    overflow-x: hidden;
  }
  .el-backtop {
    z-index: 199;
    display: none;
  }
  .mobile-show {
    display: inline-block;
  }
  .el-message-box__wrapper .el-message-box--center {
    width: 350px;
  }
}

.empty-tips-animate {
  animation: emptyTipsJump .5s ease-in-out infinite alternate;
}
.empty-emptyTipsShaing-animate {
  animation: emptyTipsShaing 1s ease-in-out infinite alternate;
}

// 判空提示动画 
@keyframes emptyTipsJump {
  from {
    color: #c0392b;
    font-weight: bold;
    transform: scale(1.1);
  }
  to { 
    color: #c0392b;
    font-weight: bold;
    transform: scale(1);
  }
}
@keyframes emptyTipsShaing {
		0% {
			transform: translateX(0%); 
			color: red;
		}
		10% {
			transform: translateX(3%); 
			color: red;
		}
		20% {
			transform: translateX(0%); 
			color: red;
		}
		30% {
			transform: translateX(3%); 
			color: red;
		}
		40% {
			transform: translateX(1%); 
			color: red;
		}
		50% {
			transform: translateX(0%); 
		}
	}
  .el-button--primary {
    font-family: "Source Sans Pro", sans-serif;
  }
</style>
<!-- 字体文件 -->
<style>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700&family=Source+Sans+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,400&display=swap');

</style>