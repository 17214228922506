<template>
  <div>
    <div class="box4">
      <div class="left">
        <div class="title">Our journey</div>
        <div class="content">It has been a long but incredible journey</div>
        <div class="footer">
          <!--Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu
          egestas morbi sem vulputate etiam facilisis pellentesque ut quis.-->
          All Eat remains dedicated to revolutionising the way people experience food, fostering culinary exploration, and supporting local businesses in the process.
        </div>
        <button class="button" @click="goDownload">
          <a href="https://alleatapp.com/m.html">Download app</a>
        </button>
      </div>
      <div class="right">
        <div class="card" v-for="(item, index) in contentArr" :key="index">
          <div class="year">{{ item.year }}</div>
          <div class="card-title">{{ item.title }}</div>
          <div class="card-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentArr: [
        /*{
          year: 2016,
          title: "Deliver X was founded",
          content:
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam.",
        },
        {
          year: 2018,
          title: "Raised Series A at $50M valuation",
          content:
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam.",
        },
        {
          year: 2018,
          title: "Raised Series A at $50M valuation",
          content:
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam.",
        },*/
        {
          year: 2017,
          title: "The Genesis",
          content:
            "In 2017, All Eat emerged as the first 0% commission platform for restaurants connecting them to hungry customers. By collaborating with a network of local eateries, All Eat gained popularity by offering a wide range of cuisines and dishes at affordable menu prices. ",
        },
        {
          year: 2019,
          title: "Expansion and Enhanced Features",
          content:
            "Driven by its early success, All Eat expanded its operations within the UK and established partnerships with prominent local restaurants offering an even wider selection of delectable options at lowest menu prices.",
        },
        {
          year: 2022,
          title: "Technological Advancements",
          content:
            "Recognizing the importance of technology in the food delivery industry, All Eat invested heavily in improving its platform's infrastructure. The introduction of AI-powered chatbots and voice assistants made the ordering process even more effortless and intuitive.",
        },
        {
          year: null,
          title: "Looking Ahead",
          content:
            "As the platform continues to grow, it plans to expand its operations globally, bringing a diverse array of culinary delights to customers worldwide, ensuring that customers can enjoy their favourite meals wherever they are.",
        }
      ],
    };
  },
  methods: {
    goDownload() {
      window.open('https://alleatapp.com/m.html')
    }
  }
};
</script>

<style lang="scss" scoped>
.box4 {
  display: flex;
  padding: 60px 120px;
  justify-content: center;
  .left {
    .title {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      /* or 111% */

      display: flex;
      align-items: center;
      letter-spacing: 1.08px;
      text-transform: uppercase;

      color: #AA0BC0;
    }
    .content {
      margin-top: 10px;
      width: 500px;
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 54px;
      /* or 123% */

      display: flex;
      align-items: center;

      color: #121212;
    }
    .footer {
      width: 460px;
      margin-top: 20px;
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      display: flex;
      align-items: center;

      color: #656565;
    }
    .button {
      margin-top: 60px;
      padding: 26px 40px;
      color: #ffffff;
      background: #09ca6a;
      border: 1px solid #09ca6a;
      // border-radius: 16px;
      border-radius: 50px;
    }
  }
  .right {
    margin-left: 60px;
    .card {
      padding: 64px 54px;
      margin-bottom: 40px;
      width: 600px;
      height: 380px;
      background: #ffffff;
      border: 1px solid #ededed;
      box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
      border-radius: 24px;
      .year {
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */

        display: flex;
        align-items: center;

        color: #AA0BC0;
      }
      .card-title {
        margin-top: 10px;
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        /* or 142% */

        display: flex;
        align-items: center;

        color: #121212;
      }
      .card-content {
        margin-top: 30px;
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */

        display: flex;
        align-items: center;

        color: #656565;
      }
    }
  }
}
@media screen and (min-width:901px)and (max-width:1350px) {
      .box4{
        padding: 3% 5%;
        .left{
          width: 100%;
          height: 100%;
          .title{
            width: 100%;
            height: 100%;
          }
          .content{
            width: 100%;
            height: 100%;
          }
          .footer{
            width: 100%;
            height: 100%;
          }
         
        }
        .right{
          width:100%;
          height: 100%;
          .card{
            width:100%;
            height:100%;

          }
        }
        }
}
@media screen and (max-width:900px) {
      .box4{
        width:100%;
        height:100%;
        padding: 5% 7%;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        .left{
          flex-direction: column;
          width: 100%;
          height: 100%;
          margin-top: 1%;
          .content{
            width: 100%;
            font-size:2.1rem;
            line-height: 40px;
          }
          .footer{
            width: 100%;
            height: 100%;
          }
          .button
          {
            margin-top: 5%;
            font-weight: bold;
            font-size: 20px;
            padding: 15px 40px;
            width: 100%;
          }
        }
        .right{
          margin-left: 0%;
          margin-top: 8%;
          width:100%;
          height:100%;
          .card{
            width:100%;
            height:100%;
            margin-bottom:5%;
            padding: 20px;
            .year {
              justify-content: center;
              font-size: 35px;
            }
            .card-title {
              justify-content: center;
            }
          }
        }
      }
      
      
    }
</style>
