<template>
  <div class="search-box">
    <input
      type="text"
      v-model="keywords"
      :placeholder="$isUk?'Enter your postcode':'Enter your irecode'"
      @input="inputNow"
      @keyup="inputNow"
      @focus="focusNow = true"
      @blur="focusNow = false"
    />
    <button class="search-btn" @click="inputNow">
      <img src="@/assets/images/search.png" alt="search-img" />
    </button>
    <!-- 搜索结果内容 -->
    <div :class="['expand-box', { 'expand-box-active': (keywords || focusNow || resultList.length) }]">
      <div
        class="search-item"
        v-for="(item, ind) in resultList"
        :key="ind"
        @click="normalSearch(item)"
      >
        <span>{{ item.content }}</span>
        <i class="el-icon-loading" v-if="itemLoading == item.place_id"></i>
        <img v-else src="@/assets/images/right.png" alt="right-arrow" />
      </div>
      <div class="load-box" v-if="loading">
        <span class="loader"></span>
      </div>
      <div class="load-box" v-else-if="!loading && !resultList.length">
        No data.
      </div>
    </div>
  </div>
</template>

<script>
import { postGateway } from '@/request';
import debounce from '@/utils/debounce';
export default {
  data() {
    return {
      keywords: "",
      focusNow: false,
      resultList: [],
      loading: false,
      itemLoading: null,
    };
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    inputNow() {
      this.resultList = [];
      this.loading = true;
      this.focusNow = true;
      debounce(() => {
        this.googlePreSearch();
      }, 500);
    },
    googlePreSearch() {  // 自动补全地址
      this.loading = true;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/autocomplete`,
					method: 'GET',
					data: {
						keyword: this.keywords
					}
				}).then(res=>{
					console.log(res.data,'搜索地址会餐')
					this.loading = false;
					this.resultList = res.data.suggestions.map(x=>({
            ...x,
            content: x.description || x.address,
            place_id: x.id,
            type: "location",
          }));
				})
				.catch(err=>{
					this.loading = false;
					console.log(err,'搜索地址报错')
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/autocomplete",
        data: { keyword: this.keywords },
      })
      .then((res) => {
        this.resultList = this.resultList.concat(
          res.data.predictions.map((x) => {
            return {
              ...x,
              content: x.description,
              type: "location",
            };
          })
        );
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    },
    // 根据结果跳转页面
    normalSearch(item) {
      this.googleSearch(item);
    },
    googleSearch(item) {
      this.itemLoading = item.place_id;
      if(this.$isUk) {
				postGateway({
					url: `/customerApp/addressioSearch/get`,
					method: 'GET',
					data: {
						id: item.id
					}
				}).then(res=>{
					console.log(res,'搜索地址会餐')
					
					this.itemLoading = '';
          if (res.code == 200) {
            let { formatted_address, postcode, longitude, latitude,town_or_city, line_1, country } = res.data;
            this.$emit('getAddress', { 
              city: town_or_city,  
              country, state: '', 
              street: line_1,
              zipcode: postcode,
              formattedAddress: formatted_address.join(','), 
              lat: latitude, 
              lng: longitude  
            })
          } else {
            this.$message.error("Error happen...");
          }
				})
				.catch(()=>{
          this.searchVisible = false;
				})
				return
			}
      postGateway({
        method: "GET",
        url: "/customerWeb/googleSearch/placeGeoCode",
        data: {
          keyword: "",
          placeId: item.place_id,
        },
      }).then((res) => {
        this.itemLoading = '';
        if (res.code == 200) {
          let { city, country, state, street, zipcode, formattedAddress, lat, lng } = res.data;
          this.$emit('getAddress', { city, country, state, street, zipcode, formattedAddress, lat, lng  })
        } else {
          this.$message.error("Error happen...");
        }
      }).catch(err=>{
        this.itemLoading = '';
        console.log(err,'err')
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  height: 50px;
  margin-top: 22px;
  display: flex;
  position: relative;
  input {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-weight: 400;
    flex: 1;
    color: #8c8d8e;
    fill: #000000ad;
    padding-left: 20px;
    border-radius: 22px 0 0 22px;
    background: #eceeef;
  }

  .search-btn {
    transition: all 0.2s ease-in-out 0s;
    border-radius: 0 22px 22px 0;
    background: #27d07c;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
    }
    &:hover {
      background: #049d51;
      color: #e8e4e4;
    }
  }

  .expand-box {
    position: absolute;
    width: 90%;
    height: 0px;
    top: 50px;
    left: 12px;
    background: rgba(255, 255, 255, 0.95);
    padding: 20px 0;
    height: 0px;
    opacity: 0;
    transition: all 0.8s ease-in-out 0s;

    .search-item {
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      padding: 0 20px;
      cursor: pointer;

      .el-icon-loading {
        font-size: 18px;
        color: #aa0bc0;
        font-weight: bold;
      }

      &:hover {
        background-color: rgba(236, 230, 230, 0.95);
      }

      span {
        flex: 1;
      }

      img {
        height: 18px;
        width: 25px;
      }
    }
  }

  .load-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #fff;
      border-bottom-color: #ff3d00;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .expand-box-active {
    height: 300px;
    opacity: 1;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 950px) {
  .search-box {
    height: 46px;
    input {
      font-size: 16px;
    }
    .expand-box {
      width: 100%;
      left: 0px;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      .search-item {
        height: unset;
        padding: 10px 5px 10px 20px;
        img {
          height: unset;
          width: 13px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
