<template>
  <div class="login-box">
    <div class="content-box">
      <div class="img-bg">
        <img
          style="height: 100%; width: 100%"
          src="@/assets/images/notfoundBg.png"
          alt="nofound"
        />
        <img
          class="cuise-img"
          src="@/assets/images/cuiseNot.png"
          alt="nofound"
        />
      </div>
      <div class="back-btn">
        <i class="el-icon-back" ></i>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item >
            <span style="cursor:default;font-weight: 600;color:#09CA6A;">Login</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="bg-box"></div>

    <div class="form-box" >
      <div class="top-box"> 
        <a @click="preTab" class="back" v-if="currentTab==1">
          <i class="el-icon-back"></i>
        </a>
        <span style="flex: 1;" ></span>
        <a @click="goBack" class="close">
          <i class="el-icon-close"></i>
        </a>
      </div>

      <div class="slide-box">
        <el-carousel ref="carousel" indicator-position="none" :initial-index="currentTab" :autoplay="false" arrow="never" height="420px" 
          @change="changTab">
          <el-carousel-item key="0" style="width:100%;padding: 0 30px;">
            <div>
              <h2>Sign in with mobile number</h2>
              <p>
                Email users can still login using their number
              </p>
              <div class="input-box">
                <div class="area-box">
                  <PhoneBtn v-model="form.code"></PhoneBtn>
                </div>
                <div class="shadow-box" >
                  <input class="input" name="" v-model="form.phone" @keyup="form.phone=form.phone.replace(/\D/g,'')" placeholder="Your phone number" type="phone" />
                </div>
              </div>
              <div :class="['check-box',{'shaking': showAgreeAnimation}]" >
                <el-checkbox size="medium" v-model="agree"></el-checkbox>
                <span @click="agree=!agree">Agree</span>
                <p>
                  By submitting your number, you agree to All Eat App's 
                  <a href="/termsConditions" class="under-line">Terms & Conditions, </a>
                  <a href="/faqs" class="under-line">Privacy Policy & Cookies Policy.</a>
                </p>
              </div>
              <div :class="['sign-btn','flex-row-center',{'disable-btn':!canGetCode}]" @click="showCaptcha">{{ sendPhonecode }}</div>
              <div class="flex-row-center being-send" @click="gotCode()">
                I've got the code
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item key="1">
            <div class="phone-box">
              <h2>Enter 4 digit code</h2>
              <p>your code was send to <span class="under-line">{{ form.code }} {{ form.phone }}</span></p>
              <div class="ver-code">
                <input  v-model="form.verCode" name="" type="digital" @focus="inputFocus=true" @blur="inputFocus=false" placeholder="Verification Code"
                  @keyup="form.verCode=form.verCode.substring(0,4)" autofocus v-if="currentTab==1">
              </div>
              <div :class="['resend-btn',{'disabled':!canGetCode}]" @click="showCaptcha(true)" >{{ sendPhonecode }}</div>
              <div style="width: 400px;margin-top:20px;margin-left: 64px" :class="['sign-btn','flex-row-center',{'disable-btn':!cantLogin}]" @click="login">Confirm</div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- 蒙层 -->
        <div class="loading-box" v-if="loading">
          <el-skeleton :loading="loading" animated>
            <template slot="template">
              <el-skeleton-item variant="rect" style="height: 420px;" />
            </template>
            <div style="height: 420px;" ></div>
          </el-skeleton>
        </div>
      </div>

      
    </div>

    <!-- 验证码滑块模块 -->
    <Verify @success="sendcodeMsg"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>

  </div>
</template>

<script>
/* eslint-disable */
import { postGateway } from '@/request';
import PhoneBtn from '@/components/PhoneBtn.vue';
import Verify from '@/components/Verifition/Verify';
import bus from '@/utils/bus.js'
export default {
  metaInfo: {
    
  },
  name: "Login",
  components: {
    PhoneBtn,
    Verify,
  },
  data() {
    return {
      form: {
        phone: '',
        code: (this.$isUk?'+44':'+353'),
        verCode: '', // 手机验证码
      },
      inputFocus: false,
      currentTab: 1,
      agree: false,
      canGetCode: true,
      sendPhonecode: 'Send Verification code',
      num: 0,
      loading: true,
      showAgreeAnimation: false,
      secondSend: 0,
    }
  },
  computed: {
    cantLogin() {
      return this.form.verCode.length === 4
    }
  },
  created() {
    
  },
  mounted() {
    this.$nextTick(()=>{
      this.preTab()
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    })
  },
  methods: {
    gotCode() {
      if(!this.form.phone.trim()) {
        this.$message({
            message: 'Please enter phone number',
            type: 'warning',
            offset: 50
        })
        return
      }
      if (this.form.phone.indexOf(44) === 0) {
        this.$message({
          message: "Without the '44' country code.",
          type: "warning",
          offset: 50
        });
        return;
      }

      this.nextTab()
    },
    changTab(index) {
      this.currentTab = index;
    },
    goBack() {
      this.$router.go(-1)
    },
    preTab() {
      this.$refs.carousel.setActiveItem(0);
    },
    nextTab() {
      this.$refs.carousel.setActiveItem(1);
    },
    // 显示验证滑块
    async showCaptcha() {
      // this.sendcodeMsg({captchaVerification:''});
      // return
      /* eslint-disable */
      if(!this.num==0) return
      if(!this.form.phone.trim()) {
        this.$message({
            message: 'Please enter phone number',
            type: 'warning',
            offset: 50
        })
        return
      }
      if (this.form.phone.indexOf(44) === 0) {
        this.$message({
          message: "Without the '44' country code.",
          type: "warning",
          offset: 50
        });
        return;
      }

      // 加一个校验手机号开头
      let phoneCode = this.form.code.replace('+','');
      if(this.form.phone.indexOf(phoneCode) === 0) {
        this.$message({
          message: "Please check phone number width '" + phoneCode + "'",
          type: "warning",
        });
        return;
      }

      if(!this.agree) {
        this.$message({
            message: 'Please agree the political',
            type: 'warning',
            offset: 50
        })
        this.showAgreeAnimation = true;
        setTimeout(() => {
          this.showAgreeAnimation = false;
        }, 1000);
        return
      }
      if(!this.canGetCode) return
      this.$refs.verify.show()
    },
    //发送手机验证码
    async sendcodeMsg({captchaVerification}) {
      this.loading = true;
      let url = `/customer/atClient/smsSend`
      if (this.secondSend > 1) {
          url = `/customer/atClient/smsSendTextlocal`
      }
      postGateway({
        url: url,
        data: {
          phone: this.form.code + this.form.phone.trim(),
          captchaType: 'register',
          captchaVerification
        }
      }).then((res) => {
        this.loading = false;
        if(res.code == 200){

          if(this.currentTab < 1) { // 如果实在第一个tab 发完短信去下一个tab
            this.nextTab();
          }

          this.secondSend++;
          this.$message({
            message: 'Verification sent successfully',
            type: 'success',
            offset: 50
          })
        }
      })
      .catch(err=>{
        this.loading = false;
        console.log(err,'发送短信失败')
        this.$message({
          message: 'You have reached login attempt limit. Please try again in a few hours',
          type: 'error',
          offset: 50
        })
      })
      this.num = 60;
      this.timer2 = setInterval( ()=> {
          this.num--;
          this.canGetCode = false;
          this.sendPhonecode = 'After ' + this.num + ' seconds retry';
          if (this.num == 0) {
              this.sendPhonecode = 'Resend code';
              this.canGetCode = true;
              clearInterval(this.timer2)
          }
      }, 1000)
        
    },
    gotoPrivate() {
      this.$router.push("/Privacy-Policy");
    },
    login() {
      this.loading = true;
      let { code, phone } = this.form;
      console.log(code, phone)
   
      postGateway({
        url: '/sys/client/clientLogin',
        method: 'POST',
        data: {
          phone: code + phone,
          code: this.form.verCode,
          type: 'web'
        }
      }).then(res=>{
        this.loading = false;
        if(res.data.token) {
          let { token, refreshToken, clientId } = res.data;
          localStorage.setItem('token',token)
          localStorage.setItem('refreshToken',refreshToken)
          localStorage.setItem('clientId', clientId)
          localStorage.setItem("aeClient", clientId);
          bus.$emit('login') // 通知header登陆了
          this.$router.go(-1)
        }
        console.log(res,'登录回调')
      })
      .catch((err)=>{
        this.$message.error(err.msg)
        this.loading = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.login-box {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .bg-box {
    flex: 1;
    background-image: url("../assets/images/login_bg.png");
    background-size: cover;
    background-position: center;
  }
  .back-btn {
    position: relative;
    background: #0A191E;;
    height: 36px;
    border-radius: 18px;
    padding: 0 20px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 30px 6% 0;
    .el-icon-back {
        color: #C2F93A;
        font-size: 22px;
        margin-right: 10px;
    }
    :deep(.el-breadcrumb__inner) {
        color: #fff;
    }
    :deep(.el-breadcrumb__separator) {
        color: #fff;
    }
  }

  .content-box {
    position: relative;
    width: 100%;
    height: 420px;
    .img-bg {
      position: absolute;
      z-index: 0;
      height: 420px;
      width: 100%;
    }

    .cuise-img {
      height: 259px;
      width: 370px;
      position: absolute;
      z-index: 1;
      right: 6%;
      top: 0px;
    }
  } 
  

  .form-box {
    width: 620px;
    min-height: 480px;
    background: #fff;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    padding: 0 40px 20px;
    position: absolute;

    h2 {
      font-weight: 800;
      color: #000;
      font-size: 35px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      text-align: center;
      margin: 0;
      color: #111;
      .under-line {
        text-decoration: underline;
        margin: 0;
        cursor: pointer;
        color: #2885e2;
      }
    }

    .input-box {
      margin-top: 18px;
      display: flex;
      align-items: center;

      .area-box {
        height: 56px;
        width: 114px;
        box-shadow: 0px 2px 5px #8F9698;
        margin-right: 10px;
        border-radius: 4px;
      }
      .input {
        height: 56px;
        flex: 1;
        box-shadow: 0px 2px 5px #8F9698;
        border-radius: 4px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 600;
        &::placeholder {
          font-weight: 500;
        }
      }
    }

    .check-box {
      margin-top: 20px;
      display: flex;
      font-size: 18px;
      padding-left: 126px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    
      :deep(.el-checkbox__inner) {
        background-color: #DCDFE6;
      }
      :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
        background-color: #09CA6A;
        border-color: #09CA6A;
      }
      :deep(.el-checkbox__inner) {
        transform: scale(1.4);
      }
      span {
        margin-left: 15px;
        cursor: pointer;
      }
      p {
        margin-top: 10px;
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #474747;
        text-align: left;
        .under-line {
          text-decoration: underline;
          margin: 0;
          cursor: pointer;
          color: #2885e2;
        }
      }
    }
    @keyframes shakingAnimation {
			0% {
				transform: translateX(-3%); 
				color: red;
			}
			10% {
				transform: translateX(63); 
				color: red;
			}
			20% {
				transform: translateX(-2%); 
				color: red;
			}
			30% {
				transform: translateX(2%); 
				color: red;
			}
			40% {
				transform: translateX(-1%); 
				color: red;
			}
			50% {
				transform: translateX(1%); 
			}
		}
    .shaking {
			color: rgb(199, 12, 12);
			transform: translateX(0); 
			animation: shakingAnimation 1s ease-in-out forwards;
		}
    .sign-btn {
      margin-left: 128px;
      margin-top: 20px;
      margin-bottom: 5px;
      height: 58px;
      background-color: #09CA6A;
      color: #fff;
      font-size: 20px;
      letter-spacing: 1px;
      font-weight: 600;
      border-radius: 6px;
      box-shadow: 0px 3px 6px #8F9698;
      transition: all .2s ease-in;
      cursor: pointer;
      &:hover {
        background-color: #0fda74;
        box-shadow: 0px 5px 10px #6f7577;
      }
    }
    .being-send {
      height: 28px;
      font-size: 15px;
      margin-top: 10px;
      text-decoration: underline;
      margin-left: 128px;
      cursor: pointer;
      &:active {
        color: #6f7577;
      }
    }
    .disable-btn {
      background-color: #999;
      &:hover {
        background-color: #999;
        box-shadow: 0px 5px 10px #6f7577;
      }
    }
    .ver-code {
      margin: 40px auto 20px;
      display: flex;
      position: relative;
      width: 400px;
      overflow: visible;
      box-shadow: 0px 3px 6px #8F9698;

      input {
        height: 58px;
        z-index: 10;
        font-size: 30px;
        width: 400px;
        background-color: transparent;
        box-sizing: border-box;
        border-radius: 4px;
        letter-spacing: 40px;
        text-align: center;
        font-weight: 600;

        &::placeholder {
          letter-spacing: normal;
          font-weight: 400;
          color: #c6c8cb;
          font-size: 25px;
        }
      }
      .mask-box {
        display: none;
        // display: flex;
        // justify-content: center;
        // width: 400px;
        .font-box {
          height: 65px;
          min-width: 68px;
          border-radius: 4px;
          margin-right: 30px;
          box-shadow: 0px 3px 6px #8F9698;
          background-color: #fff;
          font-size: 30px;
          color: #000;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .ol-line {
            height: 40px;
            width: 2px;
            border-radius: 1px;
            background-color: #000;
            animation: breath 1.2s ease-in-out infinite  alternate forwards;
            position: absolute;
            left: 22%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 20;
          }
        }
      }
    }

    @keyframes breath {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .top-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;

      i {
        font-size: 26px;
        
        color: #000;
        cursor: pointer;
        transition: all ease-in .2s;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
        &:hover {
          color: $mainColor;
        }
      }
      .back {
        position: relative;
        left: -30px;
      }
      .close {
        position: relative;
        right: -40px;
      }
    }

    .slide-box {
      position: relative;
      .loading-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 110%;
        width: 100%;
        z-index: 50;
      }
      .phone-box {
        position: relative;
      }
      .resend-btn {
        height: 32px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin: 0 auto;
        color: #aa0bc0;
        &.disabled {
          color: #999;
        }
      }
    }

  }
}

@media screen and (max-width: 950px) {
  .login-box {
    width: 100vw;
    .content-box {
      .back-btn {
        display: none;
      }
      .img-bg {
        zoom: 0.4;
      }
    }
    .form-box {
      width: 94vw;
      padding: 0 0 10px;
      .top-box {
        padding: 10px;
        .close {
          right: 0;
        }
        .back {
          left: 0;
        }
      }
      .slide-box {
        h2 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
        .input-box {
          margin-top: 20px;
          .area-box {
            height: 50px;
          }
          .shadow-box {
            box-shadow: 0px 2px 5px #8F9698;
            input {
              box-shadow: unset;
              height: 50px;
              width: 90%;
              font-size: 16px;
            }
          }
        }
        .phone-box {
          .sign-btn {
            margin-left: 50%!important;
          }
        }
        .check-box {
          padding-left: 5px;
        }
        .ver-code {
          zoom: 0.8;
          position: relative;
          top: 20px;
          margin-bottom: 35px;
        }
        .sign-btn {
          width: 80%!important;
          margin-left: 50%;
          transform: translateX(-50%);
          height: 48px;
          font-size: 20px;
        }
        .being-send {
          margin-left: 0;
        }
      }
    }
  }
}

</style>
