export const judgeScreen = {
  components: {},
  data() {
    return {};
  },
  mounted() {},

  methods: {
    // 判断当前屏幕宽度 小于等于 780px 跳转h5
    judgeScreenH5(pa='') {
        let width = document.body.offsetWidth;

        let path = pa.trim();

        console.log(width,'设备宽度问题处理',path, Boolean(path))

        if(width < 780) {
          let protocol = window.location.protocol
          let host = window.location.host.includes('localhost')?'www.alleatapp.com':window.location.host;
          let pathname = window.location.pathname
          
          console.log('是h5应该跳转', protocol + '//' + host + '/alleat-h5/#' + (path?path:pathname))

          // window.location.href = protocol + '//' + host + '/alleat-h5/#' + (path?path:pathname)
        }
        return width < 780
    },
  }
};
