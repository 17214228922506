<template>
    <div class="off-comp">
        <div class="off-box" v-if="info.freeDeliveryAbovePrice || info.merchantOffs.length">
            <!-- <div class="off-title">
                <i class="el-icon-s-ticket"></i>
                <span>Today Discount</span>
            </div> -->
            <div class="discount-box" v-if="info.freeDeliveryAbovePrice">
                <i class="iconfont icon-waimai"></i>
                <span>
                    No delivery fee for orders over ￡ {{ info.freeDeliveryAbovePrice?info.freeDeliveryAbovePrice.toFixed(2):'-' }}
                </span>
            </div>
            <div class="discount-box" v-for="item in info.merchantOffs" :key="item.id" >
                <i class="el-icon-price-tag"></i>
                <span>{{ getDiscountStr(item) }}</span>
            </div>
            <div style="flex:1;"></div>
        </div>
    </div>
</template>
<script>
import { postGateway } from '@/request'

export default {
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        categoryList: {
            type: Array,
            default: () => []
        },
        value: {
            type: [String, Number],
            default: () => ''
        },
        info: {
            type: Object,
            default: () => {
                return {
                    freeDeliveryAbovePrice: '',
                    merchantId: null,
                    merchantOffs: [],
                    currencySign: ''
                }
            }
        }
    },
    computed: {
        currencySign() {
            return this.info.currencySign || ''
        }
    },
    watch: {
        'keywords': function(val) {
            if(!val) {
                this.clearSearchResult(val);
            }
        }
    },
    data() {
        return {
            keywords: '',
            resultList: [],
            showSearchCover: false,
        }
    },
    mounted() {

    },
    methods: {
        // 获取完整折扣内容
		getDiscountStr (disc) {
            let str = '';
            if( disc &&  disc.type == 'discount') {
				str = `${disc.percent} % OFF for orders above ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount}`
			}
			if( disc &&  disc.type == 'freeItem') {
				str = `${disc.freeItemNames} for orders above ${this.currencySign}${disc.amount?disc.amount.toFixed(2):disc.amount}`
			}
			
            // 中文内容
			// if(this.language == 'cn') {
			// 	// 中文
			// 	if( disc && disc.type == 'discount') {
			// 		str = `满 ￡${disc.amount?disc.amount.toFixed(2):disc.amount} 享 ${disc.percent}% 折扣`;
			// 	}
			// 	if( disc &&  disc.type == 'freeItem') {
			// 		str = `满 ￡${disc.amount?disc.amount.toFixed(2):disc.amount} 赠 ${disc.freeItem}`
			// 	}
			// }
			return str;
		},
        searchFood() {
            this.$emit('searchResult', 'loading')
            postGateway({
                url: '/customerWeb/merchantDetail/getMerchantFood',
                method: 'GET',
                data: {
                    merchantId: this.info.merchantId,
                    // catId: '26838',
                    keyword: this.keywords
                }
            }).then(res=>{
                console.log(res,'搜索食品')
                this.showSearchCover = true;
                this.$emit('searchResult', res.data)
            })
            .catch(err=>{
                this.$message.error('Net Error')
                this.$emit('searchResult', [])
                this.clearSearchResult();
                console.log(err,'err');
            })
        },
        clearSearchResult() {
            this.showSearchCover = false;
            this.keywords = '';
            this.$emit('clearSearchResult','')
        },
        chooseCateId(item) {
            this.$emit('input', item.catId)
            // 滚动到指定内容区域
            let dom = document.querySelector('#catId-'+item.catId);
            const scrollTop = (document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop)/2;
            let target = dom.offsetTop + 650;
            console.log(dom.offsetTop, scrollTop , 'dom')
            if(window.innerWidth < 950) {
                target = dom.offsetTop + 470 ;
            }
            document.body.scrollTop = document.documentElement.scrollTop  = target;
            setTimeout(() => {
                this.$nextTick(()=>{
                    this.$emit('input', item.catId)
                })
            }, 500);
        },
    }
}
</script>
<style lang="scss" scoped>
    $greenColor: #26d07c;
    .off-comp {
        // width: 80vw;
        font-size: 10px;
        white-space: nowrap;
        padding: 0 8px;
        min-width: 240px;
        .off-box {
            // min-height: 180px;
            // padding-bottom: 20px;
            background-color: #D61409;
            border-radius: 8px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .off-title {
                font-size: 24px;
                font-weight: 800;
                padding-top: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                padding-left: 20px;

                i {
                    color: #fff;
                    font-size: 28px;
                    margin-right: 10px;
                }
            }
            .discount-box {
                // margin-top: 10px;
                white-space: nowrap;
                border-top: 0.5px solid #fff9;
                background-color: rgba(0, 0, 0, 0.25);
                padding: 5px 2px 5px 4px;
                color: #fff;
                font-weight: 800;

                &:nth-child(1) {
                    border-top: none;
                }

                i {
                    margin-right: 6px;
                    font-size: 16px;
                }
            }
        }
        .cate-item:nth-child(1) {
            border-radius: 4px 4px 0 0;
        }
        .now-cate {
            background-color: #e8e7e7!important;
            color: #000;
        }
    }

</style>