<template>
  <div class="payment-methods-box">
    <header class="header-box" >
      <i class="el-icon-goods" ></i>
      <span>Select payment Method</span>
    </header>
    <div class="item-box">
      <ul v-if="!loading">
        <li v-for="(item,key) in payWayList" :key="key" @click="setPaymentType(item)">
          <span :class="['radio', {'radio-active': item.checked}]"></span>
          <span class="img-box">
            <img :src="getImg(imgs[item.paymentValue].src)" :style="{width:imgs[item.paymentValue].width, height: imgs[item.paymentValue].height}" alt="" />
          </span>
          <span class="label"> {{ item.paymentName }} </span>
        </li>
      </ul>
      <div class="loading-box" v-if="loading">
        <i class="el-icon-loading" ></i>
      </div>
      <div class="loading-box" v-if="!payWayList.length && !loading" @click="queryMerchantPaymentList">
        <i class="el-icon-refresh-right" ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { postGateway } from '@/request';
import bus from '@/utils/bus';
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    merchantId: {
      type: [Number, String],
      default: null,
    },
    currencySign: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      clientId: localStorage.getItem('clientId'),
      list: ['card','cash','paypal'],
      payWayList: [],
      loading: true,
      imgs: {
        'paypal_v2': {
          src: 'assets/icon/icon_paypal.png',
          width: '24px',
          height: '28px',
          name: 'Paypal',
          sort: 2,
        },
        'paypal_pro': {
          src: 'assets/icon/icon_card.png',
          width: '32px',
          height: '23px',
          name: 'Other Card Payments',
          sort: 5,
        },
        'cod': {
          src: 'assets/icon/icon_cash.png',
          width: '30px',
          height: '30px',
          name: 'Cash',
          sort: 3,
        },
        'stp': {
          src: 'assets/icon/icon_stripe.png',
          width: '30px',
          height: '30px',
          name: 'Card Payment(Stripe)',
          sort: 4,
        },
        'viva': {
          src: 'assets/icon/icon_card.png',
          width: '30px',
          height: '30px',
          name: 'Card',
          sort: 1,
        },
        'trustPay': {
          src: 'assets/icon/icon_card.png',
          width: '30px',
          height: '30px',
          name: 'Card Pay',
          sort: 0,
        },
        'guavaPay': {
          src: 'assets/icon/icon_card.png',
          width: '30px',
          height: '30px',
          name: 'Card Payments',
          sort: 0,
        },
      },
    };
  },
  watch: {
    'merchantId': function() {
      this.getMerchantInfo();
    },
  },
  mounted() {
    setTimeout(() => {
      this.queryMerchantPaymentList();
    }, 1000);
    bus.$on('newIntoCheckout',()=>{
      this.queryMerchantPaymentList();
    })
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  },
  methods: {
    getImg(path) {
      return path?require('@/' + path):''
    },
    // 获取商家支付方式
    queryMerchantPaymentList() {
      this.loading = true;
      postGateway({
        url: '/sys/paymentType/queryMerchantPayment',
        method: 'GET',
        data: {
          merchantId: this.merchantId
        }
      }).then(res=>{
        this.payWayList = [];
        res.data.forEach((ele)=>{
          if(ele.paymentValue != 'stp') {
            this.payWayList.push({
							checked: ele.paymentValue == 'paypal_v2' ?true:false,
							...ele,
              paymentName: ((ele.paymentValue == 'paypal_v2' && this.currencySign != '￡')?'Paypal': ele.paymentName)
						})
						if(ele.paymentValue == 'paypal_v2') {
							this.$emit('input', ele.paymentValue)
						}
          }
          console.log(this.currencySign,'this.currencySign--web')
				})

        this.payWayList = this.payWayList.sort((a,b)=>{
          return this.imgs[a.paymentValue].sort - this.imgs[b.paymentValue].sort
        })
        
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
        console.log(err,'报错')
      })
    },
    setPaymentType(item) {
      this.payWayList.forEach(ele=>{
        ele.checked = false;
      })
      item.checked = true;
      this.$emit('input', item.paymentValue)
    }
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #26d07c;
$yellowColor: #ffa132;
.payment-methods-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header-box {
    width: 100%;
    height: 58px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.6);
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0 15px;
    .el-icon-goods {
      margin-right: 6px;
      // color: $greenColor;
      font-size: 24px;
    }
  }
  .item-box {
    padding: 10px 10px;
    width: 86%;
    min-height: 150px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);

    .loading-box {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-icon-loading {
        font-size: 20px;
      }
      .el-icon-refresh-right {
        font-size: 26px;
        cursor: pointer;
        color: #aa0bc0;
        font-weight: bold;
      }
    }

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      font-size: 17px;
      font-weight: bold;
      color: #000;
      .label {
        font-weight: 600;
        font-size: 15px;
      }
    }
    .img-box {
      // display: inline-block;
      height: 40px;
      width: 40px;
      margin-right: 8px;
      display: inline-flex;
      align-items: center;
    }
    .radio {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 10px;
      box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
      margin-right: 15px;
      .el-icon-location {
        margin-right: 4px;
        color: #000;
      }
    }
    .radio-active {
      &::after {
        content: '';
        position: absolute;
        z-index: 5;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: $greenColor;
      }
    }
  }
}
@media screen and (max-width:950px) {
  .payment-methods-box{
    display: none;
  }
}
</style>
