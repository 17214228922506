<template>
  <div>
    <div class="box">
      <div class="left">
        <div class="title">About All Eat App</div>
        <div class="content">
          We are here to help amazing restaurants get great customers
        </div>
        <div class="footer">
          Here's a quick fact about UK restaurants- You are paying about 14
          Billion Pounds of commission every year to online takeaway platforms.
          Yes, that’s right!! 14 billion pounds!! If you’re paying that much
          commission to online food ordering platforms, then you should be
          earning more profits. Wait what? Not enough profits!! This doesn’t
          sound reasonable. Would you like a platform which provides unbeatable
          food, unbeatable price and unbeatable service without charging
          absurdly high commission?
        </div>
      </div>
      <div class="right">
        <div class="people">
          <img
            style="height: 100%; width: 100%"
            src="@/assets/about/people.png"
            alt="people"
          />
        </div>
        <div class="food">
          <img
            style="height: 100%; width: 100%"
            src="@/assets/about/food.png"
            alt="people"
          />
        </div>
        <div class="pay">
          <img
            style="height: 100%; width: 100%"
            src="@/assets/about/pay.png"
            alt="people"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  padding: 60px 120px;
  background: #f5f5f5;
  justify-content: center;
  .left {
    .title {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      /* or 111% */
      display: flex;
      align-items: center;
      letter-spacing: 1.08px;
      text-transform: uppercase;

      color: #AA0BC0;
    }
    .content {
      width: 630px;
      margin-top: 20px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 61px;
      line-height: 72px;
      /* or 118% */

      display: flex;
      align-items: center;

      color: #121212;
    }
    .footer {
      width: 630px;
      margin-top: 30px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      display: flex;
      align-items: center;

      color: #656565;
    }
  }
  .right {
    margin-left: 180px;
    position: relative;
    .people {
      width: 500px;
      height: 500px;
      border-radius: 50%;
      overflow: hidden;
    }
    .food {
      position: absolute;
      top: 260px;
      left: -140px;
      width: 240px;
      height: 280px;
    }
    .pay {
      position: absolute;
      top: 420px;
      left: 120px;
      width: 300px;
      height: 160px;
    }

    
  }
}

@media screen and (min-width:901px)and (max-width:1350px) {
      .box{
        width:100%;
        height:100%;
        padding: 5% 7%;
        display:flex;
        justify-content:center;
        align-items:center;
        .left{
          display: flex;
          width:110%;
          height: 110%;
          flex-direction: column;
          .title{
            width:100%;

          }
          .content{
            width:100%;
            font-size: 2.8rem;
            line-height: 50px;
          }
          .footer{
            width:100%;
            line-height: 30px;
          } 
        }
        .right{
          width:70%;
          height: 70%;
          margin-left: 10%;
          .food{
            position: absolute;
            top: 52%;
            left: -15%;
            width: 45%;
            height: 55%;
          }
          .people{
            position:relative;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
          }
          .pay
          {
            position: absolute;
            top: 85%;
            left: 30%;
            width: 60%;
            height: 30%;
          }
        }
      }
      
    }
@media screen and (max-width:900px) {
      .box{
        width:100%;
        height:100%;
        padding: 5% 7%;
        flex-direction: column;
        display:flex;
        justify-content:center;
        align-items:center;
        .left{
          display: flex;
          width:100%;
          height: 100%;
          flex-direction: column;
          margin: auto;
          .title{
            width:100%;
            font-size:1rem;
          }
          .content{
            width:80%;
            font-size:2.1rem;
            line-height: 35px;
          }
          .footer{
            width:100%;
            font-size: 1rem;
            line-height: 30px;
          } 
        }
        .right{
          width:70%;
          height: 70%;
          margin: auto;
          .food{
            position: absolute;
            top: 52%;
            left: -15%;
            width: 45%;
            height: 55%;
          }
          .people{
            position:relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
          }
          .pay
          {
            position: absolute;
            top: 85%;
            left: 30%;
            width: 60%;
            height: 30%;
          }
        }
      }
      
    }
</style>
