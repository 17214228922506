<template>
    <div class="order-success">
        <img class="logo" src="@assets/images/skiLogo.png" mode="widthFix" />
        <div class="logo-name">
            <span class="logo-font">All</span>
            <span class="logo-font eat">Eat</span>
        </div>
        
        <template v-if="requesting">
            <div class="logo-tips" >Please Wait</div>
            <div class="order-desc" >Synchronizing payment information...</div>
            
            <div class="loading-block"  >
                <i class="el-icon-loading" ></i>
            </div>
        </template>

        <div class="order-desc" v-else style="margin-top: 60px;" >No payment information found</div>
        
        
        <div class="order-info" >
            Reference 
            <span style="margin-left: 5px;" class="order-no" >#{{ orderId }}</span>
        </div>

        <div class="tips-box" v-if="queryTimes>5">
            Sometimes it may take 1-2 minutes <br>
            If there is no response for a long time, please confirm whether your bank card has been deducted. <br />
            If it has been deducted, please contact customer service to check the order content.  <br />
            If not, please try to place a new order.
        </div>

        <!-- <div class="btn" @click="gotoPay" >Back to check out</div> -->
    </div>
  </template>
  
  <script>
  /* eslint-disable */ 
  import { loadCustomScript } from "@paypal/paypal-js";
  import { postGateway } from '@/request';
  export default {
    name: "PayDelay",
    data() {
      return {
        orderId: "",
        orderCode: "",
        isApp: false,
        queryTimes: 0,  // 请求次数
        requesting: true,
        device: ''
      };
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.orderCode = this.$route.query.orderCode || '';
        this.device = this.$route.query.device || '';
    },
    mounted() {
        this.hideDom();
        this.loadUniappSdk();
        this.queryPaymentStatus();
    },
    methods: {
        queryPaymentStatus() {
            if(this.queryTimes > 40) {
                this.requesting = false;
                return
            }
            this.queryTimes ++;
            postGateway({
                url: `/guavaPay/checkOrderFlag`,
                method: 'GET',
                data: {
                    orderId: this.orderId,
                    time: this.$encryptByPublicKey(new Date().valueOf())
                }
            }).then(res=>{
                if(res.data === true) {
                    this.requesting = false;
                    this.$message.success('Order Success!');
                    this.$router.replace(`/orderSuccess?orderId=${this.orderId}&device=${this.device}`);
                    return
                }

                setTimeout(() => {
                    this.queryPaymentStatus();
                }, 2000);
                console.log(res,'查询用户支付状态')
            })
            .catch(err=>{
                console.error(err,'接口报错');
                setTimeout(() => {
                    this.queryPaymentStatus();
                }, 2000);
            })
        },
        loadUniappSdk() {
            loadCustomScript({
            url: "https://www.alleatapp.com/maps2/js/js.cdn.aliyun.dcloud.net.cn_dev_uni-app_uni.webview.1.5.2.js"
            }).then(()=>{
                
            })
            .catch(err=>{
                console.log(err,' script laod error.')
                this.$message.warning('Net error.')
            })
        },
        hideDom() {
            if(this.$route.query.device || localStorage.getItem('order-device')) {
                this.isApp = true;
                let header = document.querySelector('.fix-header');
                header.style.display = 'none';
                let footer = document.querySelector('.footer');
                footer.style.display = 'none';
            }
        },
        gotoPay() {

            if(this.isApp && window.uni) {
                window.uni.navigateBack({
                    delta: 1
                })
                return
            }

            let orderId = localStorage.getItem('viva-order');
            if(orderId) {
                this.$router.replace({
                    path: `/vivaWallet`,
                    query: {
                        orderId: orderId,
                        fromFail: 1
                    }
                })
            } else {
                this.goOrderList();
            }
            
        },
        goOrderList() {
            this.$router.push({
                path: `/usercenter?orderId=${this.orderId}&type=orderDone`,
            });
        }
    },
  };
  </script>
  <style lang="scss" scoped>
  $color: #000;
  .order-success {
    padding-top: 40px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
        height: 100px;
        width: 100px;
    }
    .logo-name {
        .logo-font {
            font-size: 30px;
            font-weight: bold;
            color: #aa0bc0;
        }

        .eat {
            margin-left: 8px;
            color: #26d07c;
        }
    }
    .logo-tips {
        color: #26d07c;
        font-size: 24px;
        margin-top: 45px;
    }

    .order-desc {
        color: #333;
        font-size: 20px;
        margin-top: 5px;
    }

    .loading-block {
        margin-top: 24px;
        i {
            font-size: 32px;
        }
    }

    .order-info {
        margin-top: 28px;
        font-size: 20px;
        color: #222;

        .order-no {
            color: #000;
        }
    }

    .tips-box {
        width: 320px;
        border: 2px solid orange;
        border-radius: 8px;
        background: #fff7f7;
        padding: 12px 18px;
        margin-top: 24px;
    }

    .btn {
        cursor: pointer;
        height: 48px;
        box-sizing: border-box;
        padding: 6px 80px;
        background-color: #aa0bc0;
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        border-radius: 24px;
        margin-top: 20px;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 3px 6px, rgba(0, 0, 0, 0.53) 0px 3px 6px;
    }

  }
  
  @media screen and (max-width: 950px) {
    .order-success {
        min-height: 400px;
        display: flex;
        justify-content: center;
        .order-info {
            margin-top: 8px;
            font-size: 30px;
            white-space: nowrap;
            color: #222;

            .order-no {
                color: #000;
            }
        }
    }
  }
  </style>
  