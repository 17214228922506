<template>
    <div class="trust-payment" >
        <form id="st-form" action="https://alleatapp.com/base/trustPay/getTrustPay" method="POST">
            <div id="st-card-number"></div>
            <div id="st-expiration-date"></div>
            <div id="st-security-code"></div>
            <button type="submit">Pay securely</button>
        </form>
        <!-- <div id="st-notification-frame" v-if="canShowTips"></div> -->
    </div>
</template>

<script>
import { postGateway } from '@/request';

/* eslint-disable */
    export default {
        name: "TrustPayment",
        data() {
            return {
                origin: 'https://www.alleatapp.com',
                orderId: '',
                canShowTips: false,
            }
        },
        mounted() {
            this.orderId = this.$route.query.orderId || '';
            this.origin = location.origin + `/orderSuccess?orderId=${this.orderId}`;
            // console.log(this.origin,'origiin')

            this.getJwtByOrderId();

            setTimeout(() => {
                this.canShowTips = true;
            }, 1500);
        },
        methods: {
            getJwtByOrderId() {
                postGateway({
                    url: '/trustPay/getJwtToken',
                    method: 'GET',
                    data: {
                        orderId: this.orderId
                    }
                }).then(res=>{
                    console.log(res.data,'res 获取 jwt');
                    this.loadTrustPayment(res.data || '');
                })
                .catch(err=>{
                    console.log(err,'err')
                })
            },
            loadTrustPayment(token) {

                // 获取jwt

                var st = window.SecureTrading({
                    jwt : token,
                    livestatus: 0, // 3ds 0 - test 环境生效  1- 生产环境生效
                    // disabledAutoPaymentStart: ["APPLEPAY", "CARD", "GOOGLEPAY"]
                });
                st.Components();
            }
        }
    }
</script>

<style lang="scss" scope>
    .pay-btn {
        height: 40px;
        width: 100px;
    }
</style>