import { postGateway } from '@/request'


export function isUkIp() {
    return new Promise((resolve, reject) => {
        postGateway({
                url: `/customerApp/merchantDetail/getIpAddr`,
                method: 'GET',
                data: {}
            }).then(res => {
                if (res.data.flag * 1) {
                    resolve({
                        isUk: true,
                        countryCode: res.data.countryCode?res.data.countryCode:'GBR'  //默认给一个
                    })
                } else {
                    reject({
                        isUk: false
                    })
                }
            })
            .catch(() => {
                reject({
                    isUk: false
                })
            })
    })
}

export function getUserCountryCode() {
    return new Promise((resolve) => {
        postGateway({
                url: `/customerApp/merchantDetail/getIpAddr`,
                method: 'GET',
                data: {}
            }).then(res => {
                resolve({
                    isUk: res.data.flag * 1,
                    countryCode: res.data.countryCode?res.data.countryCode:'GBR'  //默认给一个
                })
            })
            .catch(() => {
                resolve({
                    isUk: false,
                    countryCode: 'GBR'
                })
            })
    })
}