<template>
  <div>
    <div class="box7">
      <div>
        <div class="title">Press & News</div>
        <div class="content-box">
          <div class="content">See what the press says about All Eat</div>
        </div>
        <div class="footer-box">
          <div class="footer">
            <div class="card" v-for="(item, index) in contentArr3" :key="index">
              <div class="img">
                <viewer :images="contentArr3"> 
                <img
                  style="height: 100%; width: 100%"
                  :src="item.img"
                  alt="people"
                />
                </viewer>
              </div>
              <!-- <div class="title">{{ item.title }}</div>
              <div style="display: flex; justify-content: space-between">
                <div class="left">{{ item.content }}</div>
                <div class="right">{{ item.time }}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentArr3: [
        {
          img: 'https://nweb.alleatapp.com/food/img/20230712/A9E9FD74EF42AE44F6560FBA6E212AA2.png',
          title: "Deliver X raises $26M in series B funding for growth",
          content: "Read more",
          time: "January 22, 2023",
        },
        {
          img: 'https://nweb.alleatapp.com/food/img/20230712/1A767CA1F33BF3B91D7DC8CB35833410.png',
          title:
            "Deliver X claims to be the #1 player in the delivery industry",
          content: "Read more",
          time: "Nov 15, 2022",
        },
        {
          img: 'https://nweb.alleatapp.com/food/img/20230712/EFE6B4AE0BDD934E81617E9E3681404D.png',
          title: "Introducing curbside ordering with Deliver X",
          content: "Read more",
          time: "Sep 18, 2022",
        },
        {
          img: 'https://nweb.alleatapp.com/food/img/20230712/4C1BE17F823DB3DA6CA08C99B04CB791.png',
          title: "Introducing the Square POS integration with Deliver X",
          content: "Read more",
          time: "Mar 12, 2022",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.box7 {
  padding-top: 160px;
  padding-bottom: 160px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  .title {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.08px;
    text-transform: uppercase;

    color: #AA0BC0;
  }
  .content-box {
    display: flex;
    justify-content: center;
    .content {
      width: 520px;
      margin-top: 10px;
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 54px;
      /* or 123% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #121212;
    }
  }

  .footer-box {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .footer {
      width: 1120px;
      display: flex;
      flex-wrap: wrap;
      .card {
        width: 540px;
        // height: 300px;
        // padding: 40px 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 10px;
        // background: #ffffff;
        // border: 1px solid #ededed;
        box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
        border-radius: 24px;
        overflow: hidden;
        .img {
          width: 100%;
          // height: 40px;
          margin-bottom: 0;
          background-color: #fff;
          cursor: pointer;
        }
        .title {
          display: contents;
          width: 500px;
          font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          line-height: 40px;
          /* or 148% */
          color: #121212;
        }
        .left {
          margin-top: 20px;
          font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          /* identical to box height, or 111% */
          color: #AA0BC0;
        }
        .right {
          margin-top: 20px;
          font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          line-height: 22px;
          /* or 116% */
          color: #656565;
        }
      }
    }
  }
}
@media screen and (min-width:901px)and (max-width:1350px) {
      .box7{
        width:100%;
        height:100%;
        justify-content:center;
        align-items:center;
        .footer-box
        {
          .footer{
            margin-left: 8%;
            width: 95%;
            .card{
              width:45%;
              .title{
                font-size: 1.5rem;
              }
            }
          }
        } 
      }
}
@media screen and (max-width:900px) {
  .box7{
        width:100%;
        height:100%;
        justify-content:center;
        align-items:center;
        .title{
          margin-top: 1%;
        }
        .content-box{
          .content{
            width: 100%;
            font-size:2.1rem;
            justify-content:center;
          }
        }
        .footer-box
        {
          
          margin-top: 5%;
          width: 100%;
          .footer{
            width: 100%;
            .card{
              margin: 1% 5%;
              width:100%;
              .img{
                width:100%;
              }
      
              }
            }
          }
        } 
    }
</style>
