<template>
  <div>
    <!-- 商家预定弹窗 -->
    <el-dialog
      :visible.sync="showModal"
      :append-to-body="true"
      :close-on-click-modal="false"
      :width="isMobile ? '80%' : '540px'"
      center
      @close="closeModal"
      top="200px"
    >
      <div class="modal-title" slot="title">
        <h2>Faild to load Paypal</h2>
        <div class="desc-2">
          Please switch to a stronger internet connection.
        </div>
        <div class="desc-3" >
          If you experience issues with PayPal payment, <br />
          please try using another browser or <br />
          <a href="http://itunes.apple.com/cn/app/id1603420846?mt=8" v-if="deviceType=='IOS'" >download All Eat app</a>
          <a href="https://play.google.com/store/apps/details?id=io.dcloud.uniAllEat" v-else >download All Eat app</a>
          .
        </div>
      </div>
      <div class="btn-box">
        <!-- <div class="btn btn-cancel" @click="closeModal">Cancel</div> -->
        <div class="btn" @click="confirm">
          <span>Reload</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LoadErrorModal',
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      showModal: false,
      deviceType: '',
    };
  },
  watch: {
    'value': function(val){
      this.showModal = val;
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 950;
    },
  },
  mounted() {
    this.showModal = this.value;
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
      //alert(navigator.userAgent); 
      this.deviceType ="IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
      //alert(navigator.userAgent); 
      this.deviceType ="Android";
    } else { //pc
      this.deviceType ="PC";
    }
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    closeModal() {},
  },
};
</script>

<style scoped lang="scss">
.modal-title {
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  h2 {
    font-weight: 700;
    font-size: 22px;
    margin: 0 0 5px;
  }
  .desc-1 {
    font-size: 18px;
    margin-top: 20px;
  }
  .desc-2 {
    font-size: 16px;
    margin-top: 18px;
    word-break: normal;
  }
  .desc-3 {
    font-size: 14px;
    margin-top: 20px;
    word-break: normal;
    color: #666;

    a {
      color: rgb(35, 35, 235);
      text-decoration: underline;
    }
  }
}
.btn-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .btn {
    height: 48px;
    width: 120px;
    border-radius: 16px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #09ca6a;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all ease-in 0.2s;
    &:hover {
      background: #09a859;
    }
  }
  .btn-cancel {
    border: 1px solid #aa0bc0;
    background: #fff;
    color: #aa0bc0;
    &:hover {
      background: #aa0bc0;
      color: #fff;
    }
  }
}
</style>
