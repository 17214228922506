<template>
    <div>
        <div class="merchant-cont scroll-box-x" v-if="offmerchantList.length && showOffMerchant">
            <div class="spec-item" >
                <img class="special" src="@/assets/images/special.png" alt="special">
                <img class="discount" src="@/assets/images/discount.png" alt="discount">
            </div>
            <div class="scroll-box">
                <!-- swiper 部分 -->
                <swiper :options="swiperOption" ref="mySwiper" id="spec-swiper" >
                    <swiper-slide v-for="item in offmerchantList" :key="item.merchantId" >
                        <MerchantItem  :key="item.merchantId" class="merchant-item" @click="goMerchant(item)" :item="item"></MerchantItem>
                    </swiper-slide>

                    <!-- 可选的控件 -->
                    <!--前进后退按钮-->
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
        </div>

        <h2 class="page-title" >Top Restaurants near you</h2>
        <div class="merchant-cont merchant-cont-normal" >
            <MerchantItem v-for="item in merchantList" :key="item.merchantId" class="merchant-item" @click="goMerchant(item)" :item="item"></MerchantItem>
            <br />
        </div>

        <!-- 移动设备的商家优惠展示 -->
        <div class="special-off-box" v-if="offmerchantList.length && showOffMerchant">
            <div class="top-box" >
                <img src="@/assets/browse/off-icon.png" alt="off-icon">
                <div class="off-title-box">
                    <span class="off-title" >Special</span>
                    <span class="off-title" style="padding-left: 60px;margin-top: -10px;">Offers</span>
                </div>
                <span class="seeall-btn" >See All</span>
            </div>
            <div class="item-box">
                <MerchantItem v-for="item in offmerchantList" :key="item.merchantId" :style="{ 'width': offmerchantList.length==1?'90vw':''}"
                class="merchant-item merchant-item-spec" @click="goMerchant(item)" :item="item"></MerchantItem>
            </div>
        </div>
        <div class="mobile-filter-tips" >
            <span class="tips">{{ merchantTotal }} Restaurant(s) near me</span>
            <i class="el-icon-s-unfold" ></i>
        </div>
        <div class="mobile-merchant-item">
            <MerchantItem v-for="item in merchantList" :key="item.merchantId" class="merchant-item" @click="goMerchant(item)" :item="item"></MerchantItem>
        </div>

        <!-- 空内容显示 -->
        <div class="empty-box" v-if="!merchantList.length" >
            <img src="@/assets/menu/empty_cart2.png" alt="empty">
        </div>

    </div>
</template>
<script>
import MerchantItem from '../MerchantItem.vue'
    export default {
        components: {
            MerchantItem
        },
        props: {
            merchantList: {
                type: Array,
                default: ()=>[]
            },
            merchantTotal: {
                type: Number,
                value: 0
            },
            offmerchantList: {
                type: Array,
                default: ()=>[]
            },
            showOffMerchant: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                merchantDefaultImg: 'https://nweb.alleatapp.com/food/img/20220709/7A135E7287DA4583507C81484A9A7695.jpeg',
                transTypeList: ['Delivery', 'Pick-up','Dine-in','Pre-order'],
                swiperOption: {
                    //幻灯片放映方向
                    direction: "horizontal", //通常不与左右按钮一同使用
                    slidesPerView: 3,
                    spaceBetween: 20,
                    //分页器配置项
                    pagination: {
                        el: ".swiper-pagination", //分页器的类名
                        clickable: true, // 点击分页器跳切换到相应的幻灯片
                        bottom: 0,
                    },
                    //前进后退按钮
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    //幻灯片播放配置项
                    loop: false, //是否循环播放
                    speed: 1000, // 发生页面切换动画时，动画的切换速度
                    autoplay: false,
                    breakpoints: {
                        1600: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1300: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                    },
                    on: {
                        click: function () {
                            // let dataset = event.target.dataset
                            // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
                        },
                    },
                },
            }
        },
        mounted() {

        },
        methods: {
            goMerchant(item) {
                console.log(item, 'item')
                this.$router.push({
                    path: '/menu-' + item.restaurantSlug
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
$greenColor: #26d07c;
.page-title {
    font-weight: 700;
    color: #000;
    font-size: 30px;
    margin: 5px 0 20px 0;
}
.swiper-button-next, .swiper-button-prev {
    background: rgba(185, 185, 185, 0.25);
    transition: all ease-in-out .2s;
    &:hover {
        background: rgb(201, 199, 199);
        color: #fff;
    }
}


.merchant-cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-family: "Source Sans Pro", sans-serif;
    .spec-item {
        margin: 5px 15px 15px 0;
        height: 358px;
        width: 300px;
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        .special {
            width: 100%;
            margin-bottom: 10px;
        }
        .discount {
            width: 100%;
        }
    }
}
.merchant-cont-normal {
    .merchant-item {
        width: 296px;
        height: 320px;
    }
    .merchant-item:nth-child(4n + 4) {
        margin-right: 0;
    }
}
.scroll-box-x {
    flex-wrap: nowrap;
    .scroll-box {
        flex: 1;
        white-space: nowrap;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            display: none;
        }
        #spec-swiper {
            padding: 3px;
            min-height: 300px;
        }
    }
}

.special-off-box, .mobile-merchant-item, .mobile-filter-tips {
    display: none;
}

.empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
        width: 500px;
        margin-top: 50px;
    }
}

// 手机相关布局
@media screen and (max-width: 950px) {

    .empty-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        img {
            width: 80vw;
            margin-top: 50px;
        }
    }
    .merchant-cont, .page-title{
        display: none;
    }
    .special-off-box {
        display: block;
        // height: 400px;
        background: #D61409;
        margin: 10px;
        // margin-top: -12px;
        border-radius: 20px;
        padding: 0 0px 10px 20px;
        overflow: hidden;

        .top-box {
            display: flex;
            padding-bottom: 8px;
            img {
                width: 60px;
                height: 64px;
            }
            .off-title {
                color: #fff;
                font-size: 28px;
                font-family: "Source Sans Pro", sans-serif;
            }
            .off-title-box {
                flex: 1;
                padding-left: 30px;
                display: flex;
                flex-direction: column;
            }
            .seeall-btn {
                margin-top: 10px;
                height: 38px;
                width: 100px;
                background: $greenColor;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 16px;
                border-radius: 19px 0 0 19px;
            }
        }

        .item-box {
            // margin-right: 20px;
            overflow-y: hidden;
            overflow-x: auto;
            white-space: nowrap;
            .merchant-item-spec {
                width: 70vw;
            }
        }

    }
    .mobile-filter-tips {
        display: flex;
        align-items: center;
        position: sticky;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        top: 0px;
        z-index: 100;
        margin-bottom: 10px;
        padding: 10px 20px 20px;
        .tips {
            flex: 1;
            font-weight: bold;
            color: #000;
            font-size: 15px;
        }
        i {
            font-size: 20px;
            color: #000;
        }
    }
    .mobile-merchant-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .merchant-item {
            margin-right: 0px;
            width: 90vw;
        }
    }
}

</style>