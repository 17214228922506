import { render, staticRenderFns } from "./BoxSix.vue?vue&type=template&id=9b4d624c&scoped=true"
import script from "./BoxSix.vue?vue&type=script&lang=js"
export * from "./BoxSix.vue?vue&type=script&lang=js"
import style0 from "./BoxSix.vue?vue&type=style&index=0&id=9b4d624c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b4d624c",
  null
  
)

export default component.exports