<template>

    <div class="checkout" :style="{ 'min-height': innerHeight }">
        <div class="content-box">
            <div class="match-box">
                <div class="break-box">
                    <div class="break">
                        <i class="el-icon-back" @click="goBack"></i>
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item @click="$router.go(-1)">Menu</el-breadcrumb-item>
                            <el-breadcrumb-item>
                                <span style="color: rgb(9, 202, 106)">Check out</span>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <h2 class="check-title">Check out</h2>
                <img class="cuise-img" src="@/assets/images/cuiseNot.png" alt="nofound" />
            </div>
        </div>

        <div class="main-content" v-if="loadDone">
            <!-- Display a payment form -->
            <div id="card-form">
                <h2 class="title">Card Payment</h2>

                <div class="trust-payment">
                    <div id="st-notification-frame"></div>
                    <form method="POST" action="https://payments.securetrading.net/process/payments/choice">
                        <input type="hidden" readonly name="orderreference" :value="orderId">
                        <!-- 使用的 trust 应用 -->
                        <!-- test环境 -->
                        <!-- <input type="hidden" name="sitereference" value="test_alleatapp127648"> -->
                        <!-- 生产环境 -->
                        <input type="hidden" name="sitereference" :value="trustPayAppId">

                        <input type="hidden" name="stprofile" value="default">
                        <input type="hidden" name="stdefaultprofile" value="st_paymentcardonly">
                        <input type="hidden" name="currencyiso3a" :value="currency">
                        <input type="hidden" name="mainamount" :value="mainAmount">
                        <input type="hidden" name="version" value="2">

                        <!-- 似乎只有这个有样式 -->
                        <input type="submit" class="pay-btn-trustpayment" value="Confirm to pay" />

                        <!-- 3DS -->
                        <!--  14 是必须3ds校验 13是由发卡机构决定是否校验 -->
                        <input type="hidden" name="scaexemptionindicator" value="13">

                        <!-- webhooks notice -->
                        <!-- 测试环境 -->
                        <!-- <input type=hidden name="ruleidentifier" value="UDR-418451"> -->

                        <!-- 正式环境 -->
                        <input type=hidden name="ruleidentifier" :value="responseId">

                        <input type=hidden name="ruleidentifier" value="STR-6">

                        <!--Update the below with the URL for the redirect-->
                        <!-- 支付成功的重定向链接 -->
                        <input type=hidden name="successfulurlredirect" :value="origin">

                        <input type=hidden name="successfulurlnotification"
                            value="https://alleatapp.com/base/trustPay/webhooks">

                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { postGateway } from '@/request';
/* eslint-disable */
export default {
    name: "TrustPayment",
    data() {
        return {
            origin: 'https://www.alleatapp.com',
            orderId: '',
            innerHeight: "",
            currency: 'GBP',   // EUR - 欧洲
            mainAmount: '',
            merchantId: '',
            loadDone: false,
            trustPayAppId: 'alleatapp127649',
			responseId: 'UDR-419052', // 
        }
    },
    mounted() {
        this.orderId = this.$route.query.orderId || '';
        this.mainAmount = this.$route.query.amount;
        this.merchantId = this.$route.query.merchantId;
        
        this.getMerchantInfo();
        console.log(this.origin, 'origiin')
    },
    methods: {
        getMerchantInfo() {
            // 获取paypal client id
            // 先获取clientid
            postGateway({
                url: "/customerApp/order/getPayClientId",
                method: "GET",
                data: {
                    merchantId: this.merchantId,
                },
            })
                .then((data) => {
                    console.log(data, "client");
                    let { currencyType, responseId, trustPayAppId } = data.data;
                    this.currency = currencyType || 'GBP';
                    if(responseId && trustPayAppId) { // trustpayment 配置
                        this.trustPayAppId = trustPayAppId;
                        this.responseId = responseId
                    } else {
                        if(this.currency == 'EUR') {
                            this.trustPayAppId = 'alleatappie131395';
                            this.responseId = 'UDR-435687'
                        } else {
                            this.trustPayAppId = 'alleatapp127649';
                            this.responseId = 'UDR-419052';
                        }
                    }
                    this.origin = 'https://alleatapp.com/base/trustPay/paymentPageWebhooks';
                    this.loadDone = true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        goBack() {
            this.$router.go(-1);
        },
    }
}
</script>

<style lang="scss" scope>
.pay-btn-trustpayment {
    height: 48px;
    width: 340px;
    background-color: #ffc43a;
    border-radius: 8px;
}

$greenColor: rgb(9, 202, 106);
$mainColor: #aa0bc0;

.checkout {
    min-height: 100vh;

    .trust-payment {
        margin: 20px auto;

        form {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .content-box {
        position: relative;
        background-image: url("@/assets/images/notfoundBg.png");
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 360px;

        .match-box {
            position: relative;
            height: 100%;
        }

        .break-box {
            position: absolute;
            box-sizing: border-box;
            top: 48px;

            .break {
                height: 370px;
                height: 44px;
                display: inline-flex;
                align-items: center;
                padding: 20px;
                border-radius: 22px;
                background-color: #0a191e;
                color: #ffffff;

                .el-icon-back {
                    color: #2680ed;
                    font-size: 22px;
                    margin-right: 10px;
                    cursor: pointer;
                }

                :deep(.el-breadcrumb__inner) {
                    font-weight: 600;
                    color: #fff;
                }

                :deep(.el-breadcrumb__separator) {
                    color: #fff;
                }
            }
        }

        .check-title {
            position: absolute;
            box-sizing: border-box;
            bottom: 48px;
            font-weight: 700;
            font-size: 70px;
            color: #fff;
            margin: 0;
        }

        .cuise-img {
            height: 259px;
            width: 370px;
            position: absolute;
            z-index: 1;
            right: 6%;
            top: 0px;
        }

        // tips
        .title-tips {
            font-family: "Poppins";
            font-size: 50px;
            color: #fff;
            font-weight: bold;
        }

        .title-desc {
            font-family: "Poppins";
            margin-top: 10px;
            font-size: 20px;
            color: #fff;
            letter-spacing: 0.5px;
        }
    }

    .page-title {
        display: flex;
        justify-content: center;
        font-size: 24px;
        color: #000;
        font-weight: 600;
        margin: 25px 0 10px;
    }

    .steps-box {
        margin: 20px auto;
        width: 1600px;
    }

    .main-content {
        margin: 20px auto;
        width: 1600px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .section {
            display: flex;
            flex-direction: column;
            margin-right: 3%;
        }

        .section-login {
            width: 924px;
        }

        .section-1 {
            width: 538px;
        }

        .section-2 {
            width: 476px;
        }

        .section-3 {
            width: 693px;
        }
    }
}

#card-form {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 12px;
    width: 400px;

    .title {
        text-align: center;
        padding-bottom: 20px;
        font-weight: 700;
    }

    .loading-box {
        display: flex;
        justify-content: center;
        font-size: 25px;
    }

    #submit {
        margin-top: 20px;
        background: #5469d4;
        color: #fff;
        font-weight: 600;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
    }

    #card-holder-name {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 12px;
        margin-top: 6px;
        margin-bottom: 16px;
        color: #3a3a3a;
        font-family: helvetica, tahoma, calibri, sans-serif;
        font-size: 17px;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-weight: normal;
        resize: vertical;
    }
}

.paypal-button-container {
    width: 100%;
    max-width: 760px;
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
}

.card_container {
    width: 100%;
    max-width: 760px;
    padding: 20px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
}

.card_field {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 16px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
    font-size: 17px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

button {
    border: none;
}

input {
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 20px;
}

@media screen and (max-width: 950px) {
    .checkout {
        min-height: unset !important;

        .content-box {
            height: 48px;

            .match-box {
                width: 100vw;

                .check-title {
                    font-size: 20px;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    bottom: unset;
                }

                .break-box,
                .cuise-img {
                    display: none;
                }

            }
        }

        .main-content {
            width: 100vw;

            #card-form {
                width: 97vw;

                #submit {
                    font-size: 20px;
                }
            }
        }
    }

}
</style>