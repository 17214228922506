<template>
  <div>
    <div class="flex-center title-one">Contact us</div>
    <div class="flex-center content-one">
      Whether you have a question, a suggestion, or just want to say hello, we're here for you.
    </div>
    <!-- card -->
    <div class="flex-center card-contact">
      <div class="card flex">
        <div class="form">
          <el-form :label-position="'top'" label-width="80px" :model="form">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Name">
                  <el-input v-model="form.name" placeholder="John Carter"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Email">
                  <el-input v-model="form.email" placeholder="example@email.com"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="Phone">
                  <el-input v-model="form.phone"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="Subject">
                  <el-select v-model="form.subject" placeholder="Select Option">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="20">
                <el-form-item label="Order ID / Reference number" >
                  <el-input v-model="form.orderId" type="number" placeholder="#412357"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Leave us a message">
                  <el-input v-model="form.message" type="textarea" placeholder="..."></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-button type="success" :loading="btnLoading" @click="showVerify" style="font-weight: 600;" >Send Message</el-button>
        </div>
        <div class="details">
          <div class="title">Contact details</div>
          <div class="content">
            Get fast support via call, email and live chat
          </div>
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col>
              <div class="title2">Address</div>
              <div class="text">3rd floor, Howard House, 32-34 High St, Croydon CR0 1YB</div>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col>
              <div class="title2">Hours</div>
              <div class="text">Mon-Sun 9:00AM - 11:00PM</div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 20px">
            <el-col :span="12">
              <div class="title2">Email</div>
              <div class="text">info@alleatapp.com</div>
            </el-col>
            <el-col :span="12">
              <div class="title2">Phone</div>
              <div class="text">020-3953-8888</div>
            </el-col> 
          </el-row>
          <el-divider></el-divider>
          <div class="title">Follow us on social media</div>
          <div class="content">
            Connect with us on social media- We regularly post updates, exciting offers,and useful content.You can send us direct messages on these platforms,and we'll be happy to respond.
          </div>
          <div class="flex-warp" style="margin-top: 10px">
            <div class="img-card" v-for="(item, index) in images" :key="index">
              <img
                style="height: 100%; width: 100%"
                :src="item.img"
                alt="people"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 问题 -->
    <FaqsBox style="margin-top: 40px; margin: 0px 1%;"></FaqsBox>

    <!-- card -->
    <div class="card-box2">
      <div class="title flex-center">Want to reach out directly?</div>
      <div class="flex-center">
        <div class="content-box">
          No matter which method you choose to get in touch with us <br />
          We assure you that we'll do our best to respond promptly to assist you
        </div>
      </div>
      <div class="flex-center">
        <div class="card-container">
          <div
            class="card card-box"
            style="margin-right: 10px"
            v-for="(item, index) in boxArr"
            :key="index"
          >
            <div class="flex-center">
              <div class="img">
                <img
                  style="height: 100%; width: 100%"
                  :src="item.img"
                  alt="people"
                />
              </div>
            </div>
            <div class="title flex-center">{{ item.title }}</div>
            <!-- <div class="content-warp">{{ item.content }}</div> -->
            <div class="des flex-center">{{ item.des }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- card fllow us -->
    <div class="card-box3">
      <div class="title flex-center">Follow us</div>
      <div class="flex-center">
        <div class="footer-box">
          <div
            class="item card"
            v-for="(item, index) in imagesArr"
            :key="index"
          >
            <div class="flex-center">
              <div class="img">
                <img
                  style="height: 100%; width: 100%"
                  :src="item.img"
                  alt="people"
                />
              </div>
            </div>
            <div class="title-con">
              <div class="title-top">{{ item.title }}</div>
              <div class="title-bottom">Follow us</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 验证码滑块模块 -->
    <Verify @success="submitEail"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>

  </div>
</template>

<script>
import FaqsBox from '@/components/IndexComps/FaqsBox.vue';
import Verify from '@/components/Verifition/Verify';
import { postGateway } from '@/request';
export default {
  components: {
    FaqsBox,
    Verify
  },
  data() {
    return {
      form: { name: "", email: "", phone: "", subject: "", message: "",orderId:""},
      btnLoading: false,
      options: [
        {
          value: "Order Not Received",
          label: "Order Not Received",
        },
        {
          value: "Order issue",
          label: "Order issue",
        },
        {
          value: "Join All Eat",
          label: "Join All Eat",
        },
        {
          value: "Other",
          label: "Other",
        },
      ],
      images: [
        { img: require("@/assets/contact/icon1.png") },
        { img: require("@/assets/contact/x.png") },
        { img: require("@/assets/contact/icon3.png") },
        { img: require("@/assets/contact/icon4.png") },
        { img: require("@/assets/contact/icon5.png") },
      ],
      titles: [
        "Do you deliver to my postcode?",
        "Can I place an order for collection?",
        "Can I order through live chat?",
        "How much do you charge for delivery? ",
      ],
      showTipsArr: [],
      responseTip: [
        'You can browse nearby takeaways by typing the postcode in our app or the website. Each restaurant will show a delivery distance in miles which will be the radius within which they deliver. ',
        'Yes, you can opt for collection on our app or website. Just select the collection option while placing your order. You can expect to pick up your order 15-20 mins after placing the order for collection.',
        'Live chats don’t accept orders. All takeaway orders can be placed through the All Eat app or on All Eat website.',
        'The delivery charges may vary between restaurants which you can view while placing an order. Some takeaways offer free delivery on a minimum amount of orders.'
      ],
      boxArr: [
        {
          img: require("@/assets/contact/email.png"),
          title: "Send us an e-mail",
          content: "",
          des: "info@alleatapp.com",
        },
        {
          img: require("@/assets/contact/phone.png"),
          title: "Give us a call",
          content: "",
          des: "020 - 3953 - 8888",
        },
        {
          img: require("@/assets/contact/wx.png"),
          title: "Chat with us",
          content: "",
          des: "Open chat",
        },
      ],
      imagesArr: [
        { img: require("@/assets/contact/icon1.png"), title: "Facebook" },
        { img: require("@/assets/contact/x.png"), title: "Twitter" },
        { img: require("@/assets/contact/icon3.png"), title: "Instagram" },
        { img: require("@/assets/contact/icon4.png"), title: "YouTube" },
        { img: require("@/assets/contact/icon5.png"), title: "Whatsapp" },
        { img: require("@/assets/contact/icon6.png"), title: "LinkedIn" },
        { img: require("@/assets/contact/icon7.png"), title: "Yelp" },
        { img: require("@/assets/contact/icon8.png"), title: "Telegram" },
      ],
    };
  },
  methods: {
    showVerify() {

      if(!this.form.message) {
        this.$message.warning('Please fill the Message.')
        return
      }

      if(!this.form.name) {
        this.$message.warning('Please fill your name.')
        return
      }

      if(!this.form.subject) {
        this.$message.warning('Please choose the subject.')
        return
      }

      if(!this.form.email) {
        this.$message.warning('Please choose the Email.')
        return
      }

      this.$refs.verify.show()
    },
    submitEail({captchaVerification}) {
      this.btnLoading = true;
      let param = {
        ...this.form,
        content: this.form.message,
        captchaVerification
      }
      console.log(param, 'param')
      postGateway({
        url: '/customer/atClient/contactEmailSend',
        data: param
      }).then(res=>{
        console.log(res,'发送回参')
        this.btnLoading = false;
        for (const key in this.form) {
          this.form[key] = ''
        }
        this.$message.success('Send success!')
        setTimeout(() => {
          this.$router.push('/')
        }, 1000);
      })
      .catch(err=>{
        this.btnLoading = false;
        this.$message.error(err.message)
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-warp {
  display: flex;
  flex-flow: wrap;
}
.title {
  font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #121212;
}
.content {
  font-family: 'Inter', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #656565;
}
.card {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
  border-radius: 24px;
}
.title-one {
  font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  margin-top: 150px;
  font-size: 61px;
  line-height: 72px;
}
.content-one {
  font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #656565;
  margin-top: 40px;
  font-size: 18px;
  line-height: 30px;
}
.card-contact {
  margin-top: 4px;
  .form {
    padding: 70px 60px;
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 500px;
    border-radius: 24px 0px 0px 24px;
    :deep(.el-form-item__label) {
      @extend .title;
      // line-height: 20px;
      padding-left: 20px;
    }
    :deep(.el-input__inner) {
      // background: #ffffff;
      background:#fafafa;
      border: 0px solid #ededed;
      // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
      // border-radius: 10px;
      border-radius: 30px;
      height: 65px;
      font-weight: normal;
    }
    :deep(.el-textarea__inner) {
      // background: #ffffff;
      background:#fafafa;
      border: 0px solid #ededed;
      // box-shadow: 0px 2px 12px rgba(16, 16, 16, 0.08);
      // border-radius: 10px;
      border-radius: 30px;
      // min-height: 80px !important;
      min-height: 125px !important;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 80;
    }
    :deep(.el-button--success) {
      background: #09ca6a;
      border: 1px solid #09ca6a;
      // border-radius: 16px;
      border-radius: 30px;
      width: 195px;
      height: 64px;
    }
    :deep .el-input__inner{
      line-height: 1px!important;
    }
    :deep .el-input__inner::placeholder {
      font-weight: normal;
    }
    :deep(.el-button) {
      text-align: center;
    }
  }
  .details {
    padding: 58px 60px;
    width: 500px;
    // height: 500px;
    background: #fafafa;
    border-radius: 0px 24px 24px 0px;
    .title {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #121212;
      font-size: 23px;
      line-height: 34px;
    }
    .content {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #656565;
      font-size: 18px;
      line-height: 30px;
    }
    .title2 {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #121212;
      font-size: 18px;
      line-height: 20px;
    }
    .text {
      font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #656565;
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
      color: #AA0BC0;
    }
    .img-card {
      width: 44px;
      height: 44px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

.title-two {
  font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  margin-top: 100px;
  font-size: 18px;
  line-height: 20px;
  color: #AA0BC0;
}
.title-there {
  font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #121212;
  font-size: 44px;
  margin-top: 35px;
  line-height: 54px;
}
.content-two {
  font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #656565;
  width: 600px;
  margin-top: 20px;
  font-size: 18px;
  line-height: 30px;
}
.card-box {
  margin-top: 30px;
  width: 800px;
  padding: 60px 40px;
  .add {
    width: 50px;
    height: 50px;
  }
}
.card-box2 {
  background: #fafafa;
  margin-top: 100px;
  padding: 100px 0px;
  .title {
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #121212;
    font-size: 44px;
    line-height: 54px;
  }
  .content-box {
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #656565;
    font-size: 18px;
    width: 560px;
    line-height: 30px;
    margin-top: 10px;
  }
  .card-container {
    display: flex;
    margin-top: 20px;
    .card-box {
      // box-sizing: border-box;
      // padding: 20px 20px;
      padding: 50px 20px 40px 20px;
      width: 340px;
      height: 340px;
      // height: 300px;
      margin-bottom: 10px;
      .img {
        width: 80px;
        height: 80px;
      }
      .title {
        font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 700;
        color: #121212;
        font-size: 23px;
        line-height: 34px;
        margin-top: 20px;
      }
      .content-warp {
        font-family: 'Inter', sans-serif;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 400;
        min-height: 40px;
        color: #656565;
        font-size: 18px;
        line-height: 30px;
        margin-top: 10px;
        text-align: center;
      }
      .des {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #AA0BC0;
        margin-top: 20px;
      }
    }
  }
}
.card-box3 {
  background: #fafafa;
  margin-top: 100px;
  padding: 100px 0px;
  .title {
    font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #121212;
    font-size: 44px;
    line-height: 54px;
  }
  .footer-box {
    display: flex;
    flex-wrap: wrap;
    width: 880px;
    margin-top: 40px;
    .item {
      width: 200px;
      height: 100px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 10px;
      display: flex;
      .img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
      }
      .title-con {
        flex: 1;
        .title-top {
          font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
          margin-top: 10px;
          margin-left: 12px;
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 28px;
        }
        .title-bottom {
          font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
          margin-top: 10px;
          margin-left: 12px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
@media screen and (min-width:951px) and (max-width:1080px) {
  

  .content-one {
    text-align: center;
  }
  .card-contact {
    .card {
      width: 99%;
    }
    .form{
      width:100%;
      padding: 70px 40px;
      :deep(.el-form-item__label) {
        font-size: 21px;
      }
    }
    .details {
      width: 100%;
      padding: 58px 40px;
    }
  }
  .card-box2 {
    padding: 70px 0px;
    .title {
      text-align: center;
    }
    .content-box {
      text-align: center;
    }
    .card-container {
      .card-box {
        width: 270px;
        height: 270px;
      }
    }
  }
  .card-box3 {
    width: 99%;
    .flex-center {
      .footer-box {
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width:950px) {
  
  .flex {
    flex-direction: column;
  }
  .content-one {
    text-align: center;
  }
  .title-one{
    margin-top: 110px;
  }
  .card-contact {
    .card {
      width: 99%;
    }
    .form{
      width:100%;
      padding: 45px 30px;
      :deep(.el-form-item__label) {
        font-size: 20px;
      }
      // :deep(.el-col-16) {
      //   width: 80%;
      // }
    }
    .details {
      width: 100%;
      padding: 45px 30px;
    }
  }
  .card-box2 {
    padding: 70px 0px;
    .title {
      text-align: center;
    }
    .content-box {
      text-align: center;
    }
    .card-container {
      flex-direction: column;
      .card-box {
        width: 270px;
        height: 270px;
      }
    }
  }
  .card-box3 {
    width: 99%;
    .flex-center {
      .footer-box {
        justify-content: center;
      }
    }
  }
}
</style>
