<template>
  <div class="sort-box">
    <div class="title-box">
        <span class="text">Sort By</span>
        <span class="btn" @click="reset">Reset</span>
    </div>
    <div :class="['select-box', {'open':boxConfig.sort}]">
        <el-radio-group v-model="form.sort" text-color="#000" fill="#aa0bc0">
            <el-radio class="radio" :label="0">Recommended</el-radio>  <br>
            <el-radio class="radio" :label="1">Distance</el-radio>  <br>
            <!-- <el-radio class="radio" :label="2">Free Delivery</el-radio>  <br> -->
            <el-radio class="radio" :label="3">New on All Eat</el-radio>  <br>
        </el-radio-group>
    </div>
    <div class="title-box" @click="boxConfig.tastRating=!boxConfig.tastRating">
        <span class="text">Rating</span>
        <i class="icon el-icon-arrow-down" ></i>
    </div>
    <div :class="['select-box', {'open':boxConfig.tastRating}]">
        <el-radio-group v-model="form.tastRating" text-color="#000" fill="#aa0bc0">
            <el-radio class="radio radio-r" :label="5">
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
            </el-radio>  <br>
            <el-radio class="radio radio-r" :label="4">
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
            </el-radio>  <br>
            <el-radio class="radio radio-r" :label="3">
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
            </el-radio>  <br>
            <el-radio class="radio radio-r" :label="2">
                <i class="el-icon-star-on" ></i>
                <i class="el-icon-star-on" ></i>
            </el-radio>
        </el-radio-group>
    </div>
    <div class="title-box" @click="boxConfig.type=!boxConfig.type">
        <span class="text">Order Options</span>
        <i class="icon el-icon-arrow-down" ></i>
    </div>
    <div :class="['select-box', {'open':boxConfig.type}]">
        <el-radio-group v-model="form.type" text-color="#000" fill="#aa0bc0">
            <el-radio class="radio radio-r" :label="0">Pre-order</el-radio>  <br>
            <el-radio class="radio radio-r" :label="1">Delivery</el-radio>  <br>
            <el-radio class="radio radio-r" :label="2">Pick-up</el-radio>  <br>
            <el-radio class="radio radio-r" :label="3">Dine-in</el-radio>  <br>
        </el-radio-group>
    </div>
    <div class="title-box" @click="boxConfig.discount=!boxConfig.discount">
        <span class="text">Special Offers</span>
        <i class="icon el-icon-arrow-down" ></i>
    </div>
    <div :class="['select-box', {'open':boxConfig.discount}]">
        <el-radio-group v-model="form.discount" text-color="#000" fill="#aa0bc0">
            <el-radio class="radio radio-r" :label="0">Free Delivery</el-radio>  <br>
            <el-radio class="radio radio-r" :label="5">50% Off</el-radio>  <br>
            <el-radio class="radio radio-r" :label="2">20% Off</el-radio>  <br>
            <el-radio class="radio radio-r" :label="1">10% Off</el-radio>  <br>
        </el-radio-group>
    </div>
  </div>
</template>

<script>
import debounce from '@/utils/debounce';
export default {
    data() {
        return {
            form: {
                sort: 0,  // sort
                tastRating: '',   // tastRating
                type: '',  // type  preorder暂时没有
                discount: '',  // discount 
            },
            boxConfig: {
                sort: true,
                tastRating: true,
                type: true,
                discount: true,
            },
            formMap: {
                'sort': ['Recommended', 'Distance', 'Free Delivery', 'New on All Eat'],
                'tastRating': ['', '', '2 Star', '3 Star', '4 Star','5 Star'],
                'type': ['Pre-order', 'Delivery', 'Pick-up', 'Dine-in'],
                'discount': ['Free Delivery', '10% Off', '20% Off', '30% Off', '40% Off', '50% Off']
            }
        }
    },
    watch: {
        'form': {
            handler: function(val) {
                let { sort,  tastRating, type, discount:discount} = val;
                let obj = { sort,tastRating,type,discount };
                let tagList = [];
                for (const key in obj) {
                    if(obj[key] || key=='sort') {
                        tagList.push({
                            key: key,
                            name: this.formMap[key][obj[key]*1]
                        })
                    }
                }
                
                debounce(()=>{
                    this.$emit('optionChange', {...obj, tagList})
                },300)
            },
            deep: true
        }
    },
    mounted() {

    },
    methods: {
        reset() {
            this.form.sort = 0;
            this.form.star = '';
            this.form.type = '';
            this.form.discount = '';
        }
    }
}
</script>

<style lang="scss" scoped>
$mainColor: #aa0bc0;
$greenColor: #09CA6A;
$yellowColor: #ffa132;
.sort-box {
    width: 100%;
    border-radius: 8px;
    padding: 0 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;

    :deep(.el-radio-group .el-radio .el-radio__label) {
        font-family: "Roboto", Sans-serif!important;
        font-weight: bolder!important;
        color: #000;
        font-size: 16px!important;
    }

    .title-box {
        padding: 10px 6px 10px 11px;
        display: flex;
        font-size: 15px;
        font-weight: 600;
        align-items: center;
        color: #000;
        border-top: 1px solid rgba(0, 0, 0, 0.42);
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        &:nth-child(1) {
            border-top: none;
        }
        .text {
            flex: 1;
            cursor: pointer;
        }
        
        .btn {
            display: inline-block;
            width: 80px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            background: $greenColor;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            transition: all ease-in-out .2s;
            font-size: 15px;
            &:hover {
                background: #1daa63;
            }
        }
        .icon {
            color: $mainColor;
            cursor: pointer;
        }
    }
    .select-box {
        height: 0;
        overflow: hidden;
        padding: 0 15px 0 30px;
        transition: all ease-in-out .5s;
        .radio {
            font-size: 14px;
            color: #222222!important;
            margin-bottom: 8px;
            font-size: 18px;
            .el-icon-star-on {
                color: #aa0bc0;
                font-size: 18px;
            }
        }
        :deep(.el-radio.radio.is-checked) {
            .el-radio__input.is-checked .el-radio__inner {
                background: $mainColor;
                border-color: $mainColor;

                &::after {
                    display: none;
                }
            }
            

            .el-radio__label {
                color: #000;
                font-weight: bold;
            }
        }

        :deep(.el-radio.radio-r) {
                .el-radio__input .el-radio__inner {
                    border-radius: 0;
                }
            }
    }
    .open {
        padding: 15px 15px 15px 30px;
        height: 130px;
    }
}
</style>
